import React, { useState, useEffect, useRef } from "react";
import UploadService from '../../services/file.service';

const UploadFiles = ({media, setMedia}) => {
  
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  const [ids, setIds] = useState([]);
  const progressInfosRef = useRef(null);

  const selectFiles = (event) => {
    setSelectedFiles(event.target.files);
    setProgressInfos({ val: [] });
  };

  const uploadFiles = (e) => { 
    e.preventDefault();
    const files = Array.from(selectedFiles);
    let _progressInfos = files.map(file => ({ percentage: 0, fileName: file.name }));
    progressInfosRef.current = {
      val: _progressInfos,
    }
    const uploadPromises = files.map((file, i) => Upload(i, file));
    Promise.all(uploadPromises)
    setMessage([]);
  };

  const Upload = (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];
    return UploadService.upload(file, (event) => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      setProgressInfos({ val: _progressInfos });
    })
    .then((res) => {
      setMessage((prevMessage) => ([
        ...prevMessage,
        "Le fichier suivant a été téléchargé: " + file.name,
      ]));
      setMedia(ids => [...ids, res.data.id]);
    })
    .catch(() => {
      _progressInfos[idx].percentage = 0;
      setProgressInfos({ val: _progressInfos });
      setMessage((prevMessage) => ([
        ...prevMessage,
        "Impossible de télécharger le fichier suivant: " + file.name,
      ]));
    });
  };

  useEffect(() => {
    UploadService.getFiles().then((response) => {
      setFileInfos(response.data);
    });
  }, []);

  return (
    <div>
      {progressInfos && progressInfos.val.length > 0 &&
        progressInfos.val.map((progressInfo, index) => (
          <div className="mb-2" key={index}>
            <span>{progressInfo.fileName}</span>
            <div className="progress">
              <div
                className="progress-bar progress-bar-info"
                role="progressbar"
                aria-valuenow={progressInfo.percentage}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progressInfo.percentage + "%" }}
              >
                {progressInfo.percentage}%
              </div>
            </div>
          </div>
        ))}
      <div className="row my-3">
        <div className="col-8">
          <label className="btn btn-default p-0">
            <input type="file" multiple onChange={selectFiles} />
          </label>
        </div>
        <div className="col-4">
          <button
            className="btn btn-success btn-sm"
            disabled={!selectedFiles}
            onClick={uploadFiles}
          >
            Télécharger
          </button>
        </div>
      </div>
      {message.length > 0 && (
        <>
          <div className="alert alert-secondary" role="alert">
            <ul>
              {message.map((item, i) => {
                return <li key={i}>{item}</li>;
              })}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};
export default UploadFiles;