import React, {useState} from 'react';
import { useDropzone } from 'react-dropzone';
import { Container } from "react-bootstrap";
import axios from 'axios';
import FileUploadService from '../../services/file.service';
import UploadFiles from '../product/FileUpload';
import { createNoSubstitutionTemplateLiteral } from 'typescript';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};

function DropBox({ onDrop }, props) {
  const [ids, setIds] = useState([]);
  const [imageSent, setImageSent] = useState([]);
  const images = props;

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open,
    isDragAccept,
    isFocused,
    isDragReject,
  } = useDropzone({
    accept: 'image/*',
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const lists = acceptedFiles.map((list) => (
    <li key={list.path}>
      {list.path} - {list.size} bytes
    </li>
  ));

  // const uploadFiles = () => {
  //   const formData = new FormData();
  //   console.log(imageSent);
  //   formData.append('image', imageSent);
  //   formData.append('key', 'Your Api key goes here');
  //   axios.post('https://api.imgbb.com/1/upload', formData).then((response) => {
  //     console.log(response);
  //   });
  // };

  const uploadFiles = (setMedia, media) => {
    const formData = new FormData();
    console.log(imageSent);
    formData.append('filePath', imageSent);
    axios.post('media/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':'Bearer '+ (localStorage.getItem('user')).replace(/"/g, '')
      },
    })
    .then((res) => {
      setMedia(ids => [...ids, res.data.id])
      console.log(media)
    })
    .catch((err) => {
      console.log(err);
      console.log(lists);
    })
  }

  // const uploadFiles = () => { 
  //   const files = Array.from(images);
  //   const uploadPromises = files.map((file, i) => FileUploadService.upload(i, file));
  //   Promise.all(uploadPromises)
  // };

  // const uploadFiles = files.map((file, i) => UploadFiles(i, file))

  const handleFile = (e) => {
    setImageSent(e.target.files[0]);
    console.log(imageSent)
  };
  return (
    <>
      {' '}
      <section className="dropbox">
        <Container
          style={{   
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "40px",
            borderWidth: "2px",
            borderRadius: "10px",
            borderColor: "${(props) => getColor(props)}",
            borderStyle: "dashed",
            backgroundColor: "#fafafa",
            color: "black",
            fontWeight: "bold",
            fontSize: "1.4rem",
            outline: "none",
            transition: "border 0.24s ease-in-out",
          }}
          className="dropbox"
          {...getRootProps({ isDragAccept, isFocused, isDragReject })}
        >
          <input
              {...getInputProps({
                onChange: handleFile,
            })}
          />
          <p>Glisser les images ici </p>
          <button type="button" className="btn w-100 my-2 btn-hover" onClick={open} style={{ padding: '16px' }}>
            Cliquer pour sélectionner les fichiers
          </button>
          <button className="upload-btn btn btn-secondary" onClick={() => uploadFiles()}>Télécharger les images</button>
        </Container>
      </section>

        {lists.length !== 0 &&      
          <aside>
            <h4>Liste</h4>
            <p>{lists}</p>      
          </aside>
        }
    </>
  );
}
export default DropBox;