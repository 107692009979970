import React, { useState } from "react";
import { Container, Row, Image, Card, CardGroup, Button, Carousel } from "react-bootstrap";
import bg from "../../assets/img/bg-fv.png";
import logo from "../../assets/img/logofv.png";

import "./About.css";

// import vdf from "../../assets/img/vdf.png";

const Hero = () => {

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return(
    <div className="m-2">
      <Row className="justify-content-center ">
        <div className="row justify-content-center mt-4 mb-5">
          <div className="col-lg-6">
            <h1 className="act-title">FACE VALO</h1>
            <h2>
              Entreprise de <span>l'Economie Sociale et Solidaire</span> du secteur de <span>l'Insertion par l'activité économique</span> 
            </h2>
            <p style={{fontSize : "1.3em"}}>Créée en 2011, FACE Valo est une association loi 1901  Atelier Chantier d'Insertion basé à Calais. Nous intervenons dans le Calaisis avec la Matériauthèque et la Ressourcerie, et dans l'ensemble du Pas-de-Calais avec AIDOTEC.</p>            
          </div>

          <div className="col-lg-5">
            <Image src={bg} className="w-100"/>
          </div>        
        </div>
        {/* <div className="col-lg-11">
          <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={bg2}
                alt="First slide"
              />
              <Carousel.Caption>
                <h2 className="carousel-title">La Ressourcerie</h2>
                <p>Atelier Chantier d'Insertion</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={bgmat}
                alt="Second slide"
                />

                <Carousel.Caption>
                  <h2 className="carousel-title">La Matériauthèque</h2>
                  <p>Favoriser le développement durable, Contribuer à l'économie circulaire</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={vdf} alt="Third slide" />

                <Carousel.Caption>
                  <h2 className="carousel-title">Vue d'en FACE</h2>
                  <p> Notre magasin à Calais Coeur de Vie </p>
                </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div> */}
      </Row>        
    </div>
  )
}

export default Hero;