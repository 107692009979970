import { useEffect, useState } from "react";
import { Container, Nav, Row, ButtonToolbar, ButtonGroup, Button, Image, Card } from "react-bootstrap";
import { Link } from "react-router-dom"; 
import Icofont from "react-icofont";
import axios from "axios";

const OrderIndex = () => {

  const [bills, setBills] = useState([]);
  const [amount, setAmount] = useState(0);

  const getBills = useEffect(() => {
    axios({
      method: "get",
      url: "bills/",
      headers: {
        'content-type': 'application/json',
        'Authorization':'Bearer '+ (localStorage.getItem('user')).replace(/"/g, '') 
      }
    })
    .then((response) => {
      setBills(response.data);
      let amounts = bills.map((bill) => bill.total);
      setAmount(
        amounts.reduce((acc, curr) => acc + Number(curr))
      )
      // console.log(bills);
    }).catch((error) => {
      console.log(error);
    })
  }, [])

  return (
    <main className="main-content section-pagetop bg">
      <section className="section-pagetop">
        <Container>
          <h2 className="title-page">Liste des commandes</h2> 
          <Row className="m-2">
          {bills !== undefined && bills.length > 0 ? (
            <Card className="col-lg-3">
              <Card.Body>
                <p className="text-center" style={{ verticalAlign:'middle' }}>
                  Nombre de commande : <span>{bills.length}</span>
                </p>
              </Card.Body>
            </Card>
            ) : ''
          }
          {bills !== undefined && bills.length > 0 && amount !== undefined ?(
            <Card className="col-lg-3">
              <Card.Body>
                <p className="text-center" style={{ verticalAlign:'middle' }}>
                  Montant total : <span>{amount} €</span>
                </p>
              </Card.Body>
            </Card>
            ) : ''
          }  

          </Row>
          <Row>
            <div className="card">
            <table className="table table-borderless table-shopping-cart">
                <thead className="text-muted">
                  <tr className="small text-uppercase">
                    <th scope="col" width="120">Numéro</th>
                    <th scope="col">Date</th>
                    <th scope="col">Client</th>
                    <th scope="col">Statut</th>
                    <th scope="col">Produits</th>
                    <th scope="col" width="120">Facture</th>
                    <th scope="col" className="text-right" width="200"> Total</th>
                  </tr>
                </thead>
                <tbody>
                  {bills !== undefined && bills.length > 0 ? (
                      <>
                        {
                          bills.map((bill) => (
                          <tr>
                            <td style={{verticalAlign: 'middle'}}> 
                              {bill.linkedOrder.number.toUpperCase()}
                            </td>
                            <td style={{verticalAlign: 'middle'}}> 
                              {bill.linkedOrder.createdAt}
                            </td>
                            <td style={{verticalAlign: 'middle'}}> 
                              {bill.linkedOrder.user.firstName +' '+ bill.linkedOrder.user.lastName}
                            </td>
                            <td style={{verticalAlign: 'middle'}}> 
                              {bill.linkedOrder.status}
                            </td>
                            <td style={{verticalAlign: 'middle'}}> 
                              {bill.linkedOrder.products.map((prod)=> (
                                <Link to={{ pathname:'/products/' + prod.id, state:prod}} className="m-1">{prod.name}</Link>
                              ))
                              }
                            </td>
                            <td style={{verticalAlign: 'middle'}}>
                              <Link to={{ pathname:'/bill/' + bill.id, state:bill}}>{bill.number}</Link> 
                            </td>
                            <td style={{verticalAlign: 'middle'}}>
                              {bill.total} €
                            </td>
                            {/* <td style={{verticalAlign: 'middle'}}>
                            <ButtonToolbar>
                              <ButtonGroup>
                                <Link className="btn btn-sm btn-warning m-1" to={{ pathname: '/orders/' + order.id +'/edit', state:order }}>
                                  <Icofont icon="pencil" />
                                </Link>
                              </ButtonGroup>
                              <ButtonGroup>
                                <Link className="btn btn-primary btn-block btn-sm m-1 " to={{ pathname:'/orders/' + order.id, state:order}} style={{ display: 'block' }}>
                                  <Icofont icon="eye"/>
                                </Link>
                              </ButtonGroup>
                              <ButtonGroup>
                                <Link className="btn btn-sm btn-danger m-1" to={{ pathname: '/orders/' + order.id +'/delete', state:order }}>
                                <Icofont icon="bin" />
                                </Link>
                              </ButtonGroup>  
                            </ButtonToolbar>
                            </td> */}
                          </tr>
                          ))
                        }
                      </>
                    ) : (<tr style={{textAlign:'center'}}>Aucune commandes réalisées</tr>)
                  }
                </tbody>
              </table>
            </div>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default OrderIndex;