import react, {useState, useEffect} from 'react';
import axios from 'axios';

import { ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icofont from 'react-icofont';

export default function CategoryIndex() {

  const [categories, setCategories] = useState([]);

  const getCategories = useEffect(() => {
    axios({
      method: "get",
      url: "categories/",
    })
    .then((response) => {
      setCategories(response.data);
      // console.log(categories)
    }).catch((error) => {
      // console.log(error);
    })
  }, [])   

  return (
  
    <section className="section-pagetop bg">
      <div className="container">
        <div className="row">
          <main className="col-12">
            <div className="card">
              <table className="table table-borderless table-shopping-cart">
                <thead className="text-muted">
                  <tr className="small text-uppercase">
                    <th scope="col" width="120">Nom</th>
                    <th scope="col">Produits</th>

                    <th scope="col" className="text-right" width="200"> Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {categories !== undefined && categories.length > 0 ? 
                    (categories.map((category) => (
                      <>
                        <tr>
                          <td style={{verticalAlign: 'middle'}}>
                            {category.name}
                          </td>
                          <td style={{verticalAlign: 'middle'}}>
                            
                          </td>
                          <td style={{verticalAlign: 'middle'}}>
                            <ButtonToolbar>
                              <ButtonGroup>
                                <Link to={{ pathname: '/category/' + category.id +'/edit', state:category }} className='m-1 btn btn-sm btn-warning'>
                                  <Icofont icon="pencil" />
                                </Link>
                              </ButtonGroup>
                              <ButtonGroup>
                                <Link to={{ pathname: '/category/' + category.id +'/delete', state:category }} className='m-1 btn btn-sm btn-danger'> 
                                  <Icofont icon="bin" />
                                </Link>
                              </ButtonGroup>  
                            </ButtonToolbar>
                          </td>
                        </tr>
                      </>
                    ))) :
                    <> <tr style={{textAlign:'center'}}>Aucune catégorie disponible</tr> </>
                  }                	
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div> 
    </section>
  );
};