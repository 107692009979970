import { Container, Nav, Card, Image, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom"; 
import bannerMat from "../../assets/img/M-cover.png";
import materiaux from "../../assets/img/materiaux.svg";

import "./About.css";

const Materiautheque = () => {
  return (
    <main className="main-content mt-2">        
      <div className="banner">
        <Image src={bannerMat} className="w-100"/>
      </div>
      <section className="section-pagetop bg fmat">
        <Container>
          <h2 className="title-page">La Matériauthèque</h2>  
          <Row>
            <h3>Pourquoi se fournir chez nous ? </h3>
            <p>A la Matériauthèque, nous vous proposons des <span> matériaux de seconde main à moindre coût. </span> </p>
            <p>Notre objectif : <span> Limiter le gaspillage et la production de déchets </span> en donnant une seconde vie aux matériaux récupérés chez nos partenaires ! </p>
          </Row>
          <Row className="justify-content-around mb-2">
            <h3> Nos matériaux </h3>
            <Image src={materiaux} className="w-100"/>
          </Row>
          <Row className="my-2"> 
            <Row className="justify-content-around col-lg-8">
              <h3>Nos services</h3>
              <Card className="col-lg-5 m-2" id="enc">
                <Card.Body>
                  <div>
                    <h4>Collecte de matériaux en entreprises et chez des particuliers </h4>
                    <p>Les agents de la Matériauthèque peuvent sur demande se déplacer dans l'agglomération Grand Calais Terres & Mers pour venir collecter des dons. Nous collectons également les fins de chantier des entreprises, du moment que les matériaux sont utilisables.</p>  
                    <p>Pour prendre rendez-vous, contactez nous au 03.59.44.37.56.</p>
                  </div>
                </Card.Body>
              </Card>
              <Card className="col-lg-5 m-2" id="enc">
                <Card.Body>
                  <div>
                    <h4>Point d'apport volontaire </h4>
                    <p>Nos agents de la matériauthèque vous acceuillent à la Ressourcerie pour récolter vos dons. </p>  
                    <p>Attention, la collecte d'apport volontaire se fait uniquement sur les horaires d'ouverture du magasin !</p>
                  </div>
                </Card.Body>
              </Card>

            </Row>
            <Row className="col-lg-4 align-items-flex-start">
              <h3>Horaires</h3>
              <Card>
                <Card.Body>
                  <Table hover>
                    <thead>  
                      <h4 style={{ fontSize: "1.4rem"}}>Horaires Magasin</h4>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Lundi</td>
                        <td>Fermé</td>
                      </tr>
                      <tr>
                        <td>Mardi</td>
                        <td>
                          <tr>09h30 - 12h30</tr> 
                          <tr>14h00 - 17h30</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Mercredi</td>
                        <td>
                          <tr>09h30 - 12h30</tr> 
                          <tr>14h00 - 17h30</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Jeudi</td>
                        <td>Fermé</td>
                      </tr>
                      <tr>
                        <td>Vendredi</td>
                        <td>
                          <tr>09h30 - 12h30</tr> 
                          <tr>14h00 - 17h30</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Samedi</td>
                        <td>
                          <tr>09h30 - 12h30</tr> 
                          <tr>14h00 - 17h30</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Dimanche</td>
                        <td>Fermé</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Row>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Materiautheque;