import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Container, Row, Col, Card } from "react-bootstrap";

import { CheckoutForm } from './Checkout';
import { useParams } from 'react-router-dom';

const PUBLIC_TEST_KEY = "pk_test_51KHQLrET3DeVwbk2UaR2o9f37vLKcTtXOQtvAtNZDuE3GwvYakEND3gqgPdquaXffLrWPMa1fsX0kCkVxneRxQNW00kVrrSteY";
const stripeTestPromise = loadStripe(PUBLIC_TEST_KEY);

// const PUBLIC_KEY = "pk_live_51KHQLrET3DeVwbk2QEuX7xR0o0ZXI47wEyXAumsVt4BQR5rkk606qfikIVejar7NVAjoxqrhmXgb93p7V4L8hVEb00Rs7cxuOJ";

// const stripeTestPromise = loadStripe(PUBLIC_KEY);

export default function Payment(props) { 

const { total } = props;

  return (
    <main>
      <div className="main-content mt-1">  
        <Row className='justify-content-center'>
          <Container className="m-2 col-10">
            <Row>           
              <h1 className="my-5 text-center">Paiement</h1>
              <div className="my-3 col-lg-6">
                <Card>
                  <Card.Body>
                    <Elements stripe={stripeTestPromise}>
                      <CheckoutForm total={total} />
                    </Elements>
                  </Card.Body>
                </Card>
              </div>   
               
              <div className="col-lg-5">
                <div className="card">
                  <div className="card-body">
                    <dl className="dlist-align">
                      <dt>Prix total:</dt>
                      <dd className="text-right">{total} €</dd>
                    </dl>
                    <dl className="dlist-align">
                      <dt>Remise</dt>
                      <dd className="text-right"> 0 </dd>
                    </dl>
                    <dl className="dlist-align">
                      <dt>Total:</dt>
                      <dd className="text-right  h5"><strong> {total} €</strong></dd>
                    </dl>
                    <hr />
                  </div>
                </div>
              </div>       
            </Row>
          </Container>
        </Row>
      </div>
    </main>

  );
};