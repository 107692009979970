import { Container, Nav, Row, Card, Table, Image, Alert } from "react-bootstrap";
import Icofont from "react-icofont";
import { Link } from "react-router-dom"; 
import bannerVdf from "../../assets/img/VDF-cover.png";
import imgvdf from "../../assets/img/vdf1.jpg";
import imgvdf2 from "../../assets/img/vdf2.jpg";
import imgvdf3 from "../../assets/img/vdf3.jpg";
import imgvdf4 from "../../assets/img/vdf4.jpg";
import imgvdf5 from "../../assets/img/vdf5.jpg";

import "./About.css";

const Vuedenface = () => {
  return (
    <main className="main-content mt-2">
      <div className="banner">
        <Image src={bannerVdf} className="w-100" /> 
      </div>
      <section className="section-pagetop bg fvdf">
        <Container>
          <h2 className="title-page">Vue d'en FACE</h2>
          <Row> 
            <div className="col-6">
              <h3>Qu'est ce que Vue d'en FACE ?</h3>
              <p>Vue d'en FACE est notre magasin de centre ville. Implanté au centre commercial Calais Coeur de Vie, ce second magasin à deux objectifs :</p>
              <ul>
                <li>Permettre à des personnes en CDDI de terminer leur carrière professionnelle.</li>
                <li>Exposer et vendre les pièces réalisées via l'équipe Relooking.</li>
              </ul>
              <Row>
                <p>Suivez nous sur <a href="https://www.facebook.com/profile.php?id=61556164055980">Facebook</a></p>
              </Row>
              <div>
                <Image src={imgvdf2} rounded className="w-100" />
              </div>
            </div>
            <div className="col-6">
              <Image src={imgvdf4} thumbnail className="img-fluid col-6"/>
              <Image src={imgvdf5} thumbnail className="img-fluid col-6" />
            </div>
          </Row>

          <Row className="my-2">
            <Alert variant="warning" className="m-1 d-flex justify-content-center align-items-center">   
              <h4> Des nouveautés chaques semaines ! </h4>
            </Alert>
            <Row className="justify-content-around col-lg-8">
              <h3>Nos services</h3>
              <Row className="col-lg-7">
                <Card className="m-1" id="enc">
                  <Card.Body>
                    <div>
                      <h4>Vente de meubles aérogommés</h4>
                      <p>Meubles réalisés par l'équipe Relooking. </p>  
                      <Image src={imgvdf} className="img-fluid"></Image>
                    </div>
                  </Card.Body>
                </Card>
              </Row>
              <Row className="col-lg-5">
                <Card className="m-1" id="enc">
                  <Card.Body>
                    <div>
                      <h4>Vente d'objets de décoration</h4>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="m-1" id="enc">
                  <Card.Body>
                    <div>
                      <h4>Vente de livres</h4>
                      <Image src={imgvdf3} thumbnail className="img-fluid"></Image>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="m-1" id="enc">
                  <Card.Body>
                    <div>
                      <h4>Livraison possible ! </h4>
                    </div>
                  </Card.Body>
                </Card>
              </Row>
            </Row>
            <Row className="col-lg-4 align-items-flex-start">
              <h3>Horaires & infos</h3>
              <Card>
                <Card.Body>
                  <Table hover>
                    <thead>  
                      <h4 style={{ fontSize: "1.3rem"}}>Horaires Vue d'en FACE</h4>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Lundi</td>
                        <td>
                          <tr>11h00 - 19h00</tr> 
                        </td>
                      </tr>
                      <tr>
                        <td>Mardi</td>
                        <td>
                          <tr>11h00 - 19h00</tr> 
                        </td>
                      </tr>
                      <tr>
                        <td>Mercredi</td>
                        <td>
                          <tr>11h00 - 19h00</tr> 
                        </td>
                      </tr>
                      <tr>
                        <td>Jeudi</td>
                        <td>
                          <tr>11h00 - 19h00</tr> 
                        </td>
                      </tr>
                      <tr>
                        <td>Vendredi</td>
                        <td>
                          <tr>11h00 - 19h00</tr> 
                        </td>
                      </tr>
                      <tr>
                        <td>Samedi</td>
                        <td>
                          <tr>11h00 - 19h00</tr> 
                        </td>
                      </tr>
                      <tr>
                        <td>Dimanche</td>
                        <td>Fermé</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Row>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Vuedenface;