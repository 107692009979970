import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import React, {useState, useEffect} from 'react';

import MainNavbar from './components/base/Navbar';
import Footer from './components/base/Footer';
import { BrowserRouter } from "react-router-dom";

import authService from './services/auth.service'; 

import './App.css';  

const stripePromise = loadStripe('pk_test_51KHQLrET3DeVwbk2UaR2o9f37vLKcTtXOQtvAtNZDuE3GwvYakEND3gqgPdquaXffLrWPMa1fsX0kCkVxneRxQNW00kVrrSteY');
 
function App() {

  // const options = {
  //   clientSecret: '{{CLIENT_SECRET}}',
  // };

  const [id, setId] = useState();

  const [isAdmin, setIsAdmin] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
 
  useEffect(() => {
    if(JSON.parse(localStorage.getItem('user'))) {
      setId(authService.getCurrentUser());
      if (id) {
        setCurrentUser(authService.getUserData(id, setCurrentUser));
        
        if(currentUser !== undefined && currentUser.roles[0] === 'ROLE_ADMIN'){
          setIsAdmin(true);
          // console.log("is admin");   
        }
        return () => {
          setIsAdmin(false)
          // console.log('not admin'); 
        } 
      }
    }
  }, [id]); 

  return (
    <BrowserRouter>
      <Elements stripe={stripePromise}>
        <MainNavbar currentUser={currentUser} setCurrentUser={setCurrentUser} isAdmin={isAdmin} setIsAdmin={setIsAdmin} />
        <Footer />
      </Elements>
    </BrowserRouter>
  );
}

export default App;