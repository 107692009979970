import { Container, Nav , Card } from "react-bootstrap";
import Icofont from "react-icofont";
import { Link } from "react-router-dom"; 

const OrderSuccess = (props) => {
  return (
    <main className="main-content mt-3">
      <Container>
        <div className="col-8 mx-auto">
          <Card>
            <Card.Body>
              <h2 className="text-center">Merci pour votre commande !</h2>
              <p className="text-center">Vous pouvez retrouver votre facture sur l'espace <Link to="/account">Mon compte</Link>.</p>
            </Card.Body>
          </Card>
          {/* <Icofont icon="check-circled" />  */}
        </div>
      </Container>
    </main>
  );
};

export default OrderSuccess;