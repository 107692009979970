import React, {useState, useEffect, useRef} from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"; 
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import axios from "axios";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger mt-1" role="alert">
        Ce champ est obligatoire !
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 30) {
    return (
      <div className="alert alert-danger" role="alert">
        Le mot de passe doit faire entre 6 et 30 caractères.
      </div>
    );
  }
};

const EditPassword = (props) => {

  const location = useLocation();

  let navigate = useNavigate();
  const editPasswordForm = useRef();
  const checkBtn = useRef();
  const param = useParams();

  const [plainPassword, setPlainPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [successful, setSuccessful] = useState(false);

  const onChangePlainPassword = (e) => {
    const plainPassword = e.target.value;
    setPlainPassword(plainPassword);
  };

  const onChangeRepeatPassword = (e) => {
    const repeatPassword = e.target.value;
    setRepeatPassword(repeatPassword);
  };

  const handleNewPassword = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    editPasswordForm.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      if(repeatPassword === plainPassword){
        const data = {
          password: plainPassword
        }
    
        axios({
          method: "patch",
          url: `/users/${param.id}`,
          data: data,
          headers: {  
            'Accept': "application/json",
            'Content-Type': 'application/merge-patch+json',
            'Authorization': 'Bearer '+ (localStorage.getItem('user')).replace(/"/g, ''),
            // 'Access-Control-Allow-Origin': '^http?://(localhost|127\.0\.0\.1)(:[0-9]+)?$'
          }
        })
        .then(response => {
          setMessage(response.data.message);
          setSuccessful(true);
          // navigate('/account'); 
          // window.location.reload();
        })
        .catch(err => {
          const resMessage =
            (err.response &&
              err.response.data &&
              err.response.data.message) ||
            err.message ||
            err.toString();

          setMessage(resMessage);
          setSuccessful(false);
          console.log("Une erreur est survenue :" + err)
        })
      } else {
        const resMessage = 'Les mots de passe ne sont pas les mêmes !';
        setMessage(resMessage);
        setSuccessful(false);
      }  
    } else {
      setMessage('Une erreur est survenue, veuillez rééssayer.');
    }
  }

  return (
    <main>
      {/* <SectionHeader title='Modification du mot de passe'/> */}
      <div className="main-content section-pagetop bg mt-1">
        <section className="padding-y mt-2">             
          <Container>
            <div className="card mx-auto" style={{ maxWidth: '420px', marginTop: '100px' }}>
              <div className="card-body">
                <h4 className="card-title mb-4">Saisissez votre nouveau mot de passe</h4>
                <Form onSubmit={handleNewPassword} ref={editPasswordForm}>
                  <div className="form-group">
                    <Input className="form-control" type="password" autoComplete="new-password" value={plainPassword} onChange={onChangePlainPassword} validations={[required, vpassword]} placeholder="Nouveau Mot de passe
                      " />
                  </div> 
                  <div className="form-group">
                    <Input className="form-control" type="password" autoComplete="new-password" value={repeatPassword} onChange={onChangeRepeatPassword} validations={[required, vpassword]} placeholder="Répéter le mot de passe" />
                  </div>  
                  <div className="form-group">
                    <button className="btn btn-primary btn-block" disabled={loading}>
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Valider</span>
                    </button>
                  </div>

                  {message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    </div>
                  )}

                  <div className="form-group">
                    <Link to="/forgotpassword" className="float-right m-2">Mot de passe oublié</Link> 
                  </div>
                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
              </div> 
            </div>              
          </Container>              
        </section>
      </div>
    </main>
  );
};
  
  export default EditPassword;