import { CartState } from '../../context/Context';

import defaultImg from "../../assets/img/default.png";
import { Image, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icofont from 'react-icofont';

export default function ProductIndex() {

  const {
    state: {products},
  } = CartState();

  // console.log(products)

  return (
    <main className="main-content section-pagetop bg">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <table className="table table-borderless table-shopping-cart">
                <thead className="text-muted">
                  <tr className="small text-uppercase">
                    <th scope="col" width="120">Image</th>
                    <th scope="col">Produit</th>
                    <th scope="col">Catégorie</th>
                    <th scope="col">Ref</th>
                    <th scope="col" width="120">Quantité</th>
                    <th scope="col" width="120">Prix</th>
                    <th scope="col" className="text-right" width="200"> Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {products !== undefined && products.length > 0 ? (
                      <>
                        {
                          products.map((prod) => (
                          <tr>
                            <td style={{verticalAlign: 'middle'}}>
                              {prod.media !== null ?
                                <Image src={prod.media[0].filePath} alt={prod.name} className="w-100"/>
                              : 
                                <Image src={defaultImg} className="w-100"/>
                              }
                            </td>
                            <td style={{verticalAlign: 'middle'}}> 
                              {prod.name.toUpperCase()}
                            </td>
                            <td style={{verticalAlign: 'middle'}}> 
                              {prod.category.name}
                            </td>
                            <td style={{verticalAlign: 'middle'}}> 
                              {prod.reference}
                            </td>
                            <td style={{verticalAlign: 'middle'}}>
                              {prod.quantity}
                            </td>
                            <td style={{verticalAlign: 'middle'}}>
                              {prod.price} €
                            </td>
                            <td style={{verticalAlign: 'middle'}}>
                            <ButtonToolbar>
                              <ButtonGroup>
                                <Link className="btn btn-sm btn-warning m-1" to={{ pathname: '/product/' + prod.id +'/edit', state:prod }}>
                                  <Icofont icon="pencil" />
                                </Link>
                              </ButtonGroup>
                              <ButtonGroup>
                                <Link className="btn btn-primary btn-block btn-sm m-1 " to={{ pathname:'/product/' + prod.id, state:prod}} style={{ display: 'block' }}>
                                  <Icofont icon="eye"/>
                                </Link>
                              </ButtonGroup>
                              <ButtonGroup>
                                <Link className="btn btn-sm btn-danger m-1" to={{ pathname: '/product/' + prod.id +'/delete', state:prod }}>
                                <Icofont icon="bin" />
                                </Link>
                              </ButtonGroup>  
                            </ButtonToolbar>
                            </td>
                          </tr>
                          ))
                        }
                      </>
                    ) : (<tr style={{textAlign:'center'}}>Aucun produit disponible</tr>)
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> 
    </main>

  );
};