import React, { useState, useEffect} from "react";
import { Container, Form, InputGroup, Button, Image } from "react-bootstrap";
import { Link, useNavigate, useParams  } from "react-router-dom"; 

import axios from "axios";

import { CartState } from "../../context/Context"; 
import UploadFiles from "./FileUpload";

const EditProduct = () => {

  const param = useParams();

  let navigate = useNavigate();

  const [validated, setValidated] = useState(false);

  const [name, setName] = useState();
  const [category, setCategory] = useState();
  const [description, setDescription] = useState();
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [reference, setReference] = useState();
  const [isAvailable, setIsAvailable] = useState();
  const [media, setMedia] = useState([]);

  const [categories, setCategories] = useState([]);

  const getCategories = useEffect(() => {
    axios({
      method: "get",
      url: "categories/",
    })
    .then((response) => {
      setCategories(response.data);
    }).catch((error) => {
      // console.log(error);
    })
  }, [])

  const [prod, setProd] = useState([]);

  const getProd = useEffect(() => {
    axios({
      method: "get",
      url: `products/${param.id}`,
    })
    .then((response) => {
      setName(response.data.name);
      setDescription(response.data.description);
      setPrice(response.data.price);
      setQuantity(response.data.quantity);
      setIsAvailable(response.data.isAvailable);
      setReference(response.data.reference);
      setCategory(response.data.category.name);
      setMedia(response.data.media);
    }).catch((error) => {
      // console.log(error);
    })
  }, [param.id])

  const {
    state: { cart },
    dispatch,
  } = CartState();


  const handleSubmit = e => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    e.preventDefault();

    const data = {
      name: name,
      category: category,
      description: description,
      price: price,
      quantity: quantity,
      reference: reference,
      isAvailable: isAvailable,
      // data.append(media, media);
    }

    axios({
      method: "patch",
      url: `/products/${param.id}`,
      data: data,
      headers: {  
        'Content-Type':'application/merge-patch+json',
        'Authorization':'Bearer '+ (localStorage.getItem('user')).replace(/"/g, '')
      }
    })
    .then(res => {
      // console.log(res.data);
      navigate('/product/index'); 
      window.location.reload();
    })
    .catch(err => {
      // console.log("Une erreur est survenue :" + err)
    })
  }

  return (
    <div>
      <section className="section-pagetop bg">
        <Container>
          <div class="card mx-auto" style={{ maxWidth: '520px'}}>
            <article class="card-body">
              <header class="mb-4">
                <h4 class="card-title">Modifier un produit</h4>
              </header>
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Nom</Form.Label>
                  <Form.Control type="text" class="form-control" value={name} onChange={e => setName(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Catégorie</Form.Label>
                  <Form.Select onChange={e => setCategory(e.target.value)}> 
                  <option>-- sélectionner une catégorie --</option>
                  {categories !== undefined && categories.length > 0 ? 
                    (categories.map((category) => (
                      <option value={category.id}>{category.name}</option>
                    ))) :
                    ''
                  }
                  {/* <option selected value={category.id}>{category.name}</option> */}
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control type="textarea" class="form-control" value={description} onChange={e => setDescription(e.target.value)}/>
                </Form.Group>

                <div class="form-row">
                  <div class="col form-group">
                    <Form.Label>Prix</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control type="text" value={price} class="form-control" onChange={e => setPrice(e.target.value)}/>
                      <InputGroup.Text>€</InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div class="col form-group">
                    <Form.Label>Quantité</Form.Label>
                      <Form.Control type="text" class="form-control" value={quantity} onChange={e => setQuantity(e.target.value)}/>
                  </div>
                </div>
                <Form.Group className="mb-3">
                  <Form.Check type="checkbox" label="Disponible immédiatement" checked={true} value={isAvailable} onChange={e => setIsAvailable(e.target.value)}/>
                </Form.Group>

                  <Form.Label>Images</Form.Label>            
                  <UploadFiles media={media} setMedia={setMedia}/>

                <Form.Group>
                  <p>Liste des images</p>
                  {media &&
                    media.map((image) => (
                      <>
                        <Image src={image.filePath} alt={image.fileName} width={150} />
                      </>
                    ))
                  }

                </Form.Group>

                {/* <Form.Group>
                  <Form.Label>Référence</Form.Label>
                  <Form.Control type="text" class="form-control" value={reference} onChange={e => setReference(e.target.value)}/>
                </Form.Group> */}

                <div class="form-group mt-2">
                  <Button type="submit" class="btn btn-warning btn-block"> Modifier  </Button>
                </div>              
              </Form>
            </article>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default EditProduct;