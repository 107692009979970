import React, { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { Link } from "react-router-dom"; 
import axios from "axios";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Ce champ est obligatoire
      </div>
    );
  }
};
  
const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Email invalide
      </div>
    );
  }
};

const ForgotPassword = (props) => {

  const form = useRef();
  const checkBtn = useRef();
  let navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      axios({
        method: "post",
        url: "/forgot_password/",
        headers: {
          'content-type': 'application/json',
        },
        data: {
          email: email
        }
      })
      .then((response) => {
        console.log(response);
        // if(response.data)
      })
      .catch((error) => {
        console.log(error);
      })
    }
  };

  return (
    <main>
      <div className="main-content mt-1 bg">
        <div className="section-pagetop">
          <Container>
            <h2 className="title-page">Mot de passe oublié</h2>
              <div className="card mx-auto">
                <div className="card-body">
                  <header className="mb-4">
                    <h4 className="card-title">Entrez votre adresse email</h4>
                  </header>
                  <Form onSubmit={handleSubmit} ref={form}>
                    {!successful && (
                      <>
                        <div className="col 
                        form-group">
                          {/* <label>Email</label> */}
                          <Input type="email" className="form-control" value={email} placeholder="email" onChange={onChangeEmail} validations={[required, validEmail]} />
                        </div>
                        <div className="form-group mt-2">
                          <button type="submit" className="btn btn-primary btn-block"> Réinitialiser le mot de passe  </button>
                        </div>   
                      </>
                    )}
                    {message && (
                      <div className="form-group">
                        <div
                          className={ successful ? "alert alert-success" : "alert alert-danger" }
                          role="alert"
                        >
                          {message}
                        </div>
                      </div>
                    )}
                    <CheckButton style={{ display: "none" }} ref={checkBtn} /> 
                  </Form>                  
                </div>
              </div>
          </Container>
        </div>
      </div>
    </main>
  );
};

export default ForgotPassword;