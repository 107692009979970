import { createContext, useContext, useReducer, useEffect } from "react";
import { cartReducer, productReducer } from "./Reducers";

import axios from "axios";

const Cart = createContext({});

const Context = ({ children }) => {

  const [state, dispatch] = useReducer(cartReducer, {
    products: [],
    cart: [],
  });

  const [productState, productDispatch] = useReducer(productReducer, {
    byStock: false, 
    byCategory: false, 
    byPlace: false,
    byPrice: false,
    searchQuery: "",
  });

  useEffect(() => {
    axios({
      method: "get",
      url: "products",
    })
    .then((response) => {
      dispatch({type: 'FETCH_SUCCESS', payload: response.data});
    }).catch((error) => {
      dispatch({type: 'FETCH_ERROR', payload: error})
    })
  }, []); 

  return(
    <Cart.Provider value={{ state, dispatch, productState, productDispatch }}>
      {children}
    </Cart.Provider>
  );
};

export const CartState = () => {
  return useContext(Cart)
};

export default Context;