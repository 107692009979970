import { useState } from "react";
import {  Button, ButtonGroup, Image, Row } from "react-bootstrap";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";

import { CartState } from "../../context/Context";

// assets
import defaultImg from "../../assets/img/default.png";

export default function CardRow(props){

  const {prod} = props;

  const {
    state : {cart},
    dispatch
  } = CartState();

  // console.log(cart);

    return(
      <tr>
        <td style={{ verticalAlign: 'middle' }}>
        {prod.media !== null ?
          <Image src={prod.media[0].filePath} alt={prod.name} className="img-sm" style={{ maxWidth: '300px' }}/>
        : 
          <Image src={defaultImg} className="w-100" style={{ maxWidth: '300px' }} />
        }
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          <h4>{prod.name}</h4>
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          {/* <ButtonGroup> */}
            {/* <Button variant="btn btn-light btn-sm"><Icofont icon="minus"/></Button> */}
              <Button variant="" disabled="true">{ prod.qty }</Button>
            {/* <Button variant="btn btn-light btn-sm"><Icofont icon="plus" /></Button> */}
          {/* </ButtonGroup>  */}
        </td>
        <td style={{ verticalAlign: 'middle' }}> 
          <ButtonGroup vertical>
            <var className="price">{ prod.price * prod.qty } €</var> 
            <small className="text-muted">{prod.price } €</small> 
          </ButtonGroup>
        </td>
        <td className="text-right" style={{ verticalAlign: 'middle' }}> 
          <ButtonGroup>
            <Button data-original-title="+ Liste de souhait" variant="btn btn-primary" className="m-1">   
              <Icofont icon="heart" />
            </Button> 
            <Button variant="btn btn-secondary" className="m-1" onClick={() => dispatch({ type: "REMOVE_FROM_CART", payload: prod })}> Supprimer</Button>
          </ButtonGroup>
        </td>
      </tr>
    )
}