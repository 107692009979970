import React, { useState, useEffect } from "react";
import axios from "axios";

import { useParams, Link } from "react-router-dom";

import { Button, Card, Image, Container, Row, Carousel } from "react-bootstrap";
import Icofont from 'react-icofont';

import { CartState } from "../../context/Context";

// assets
import defaultImg from "../../assets/img/default.png";

const ShowProduct = () => {

  const param = useParams();

  const [prod, setProd] = useState([]);

  const getProd = useEffect(() => {
    axios({
      method: "get",
      url: `products/${param.id}`,
    })
    .then((response) => {
      setProd(response.data);
    }).catch((error) => {
      // console.log(error);
    })
  }, [])

  const {
    state: { cart },
    dispatch,
  } = CartState();

  return (
    <main>
      <Container className="main-content mt-2">
        <Row>
          { prod !== undefined &&
            <>
              <div className="col-lg-6">
                <div className="col-lg-12 m-2">
                  {prod.media !== undefined ?
                    <>
                      {/* <Carousel>
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src="holder.js/800x400?text=First slide&bg=373940"
                            alt="First slide"
                          />
                          <Carousel.Caption>
                            <h3>First slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src="holder.js/800x400?text=Second slide&bg=282c34"
                            alt="Second slide"
                          />

                          <Carousel.Caption>
                            <h3>Second slide label</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src="holder.js/800x400?text=Third slide&bg=20232a"
                            alt="Third slide"
                          />

                          <Carousel.Caption>
                            <h3>Third slide label</h3>
                            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                          </Carousel.Caption>
                        </Carousel.Item>
                      </Carousel> */}

                      <Image src={prod.media[0].filePath} alt={prod.name} width={400} />
                    </>
                    : 
                    <Image src={defaultImg} alt={prod.name} className="img-sm"/>
                  }
                </div>

                <div className="gallery col-10 mx-auto my-2">
                  {prod.media &&
                    prod.media.map((image) => (
                      <>
                        <Image src={image.filePath} alt={image.fileName} width={150} />
                      </>
                    ))
                  }
                </div>
              </div>
              <div className="col-lg-6 thumbnail">
                <h2>{prod.name}</h2>

                <div className="mb-2">
                  <span className="price">{prod.price} €</span>
                </div>

                <p>Quantité disponible : <span className="price"> {prod.quantity}</span></p>
                <p>Description : {prod.description}</p>

                <hr />

                <Row className="col-9 mx-auto">
                  <div className="col-lg-6">
                    {cart.some((p) => p.id === prod.id) ? (
                      <Button
                        variant="btn btn-outline-danger btn-block btn-sm"
                        onClick={() =>
                          dispatch({
                            type: 'REMOVE_FROM_CART',
                            payload: prod,
                          })
                        }
                      >
                        X
                      </Button> 
                      ) : (
                        <Button 
                          onClick={() => 
                            dispatch({
                              type: 'ADD_TO_CART',
                              payload: prod
                            })
                          }
                          variant="btn btn-outline-primary btn-block btn-sm" disabled={!prod.isAvailable}>
                          {!prod.isAvailable ? "Non disponible" : <> <Icofont icon="cart" /> Ajouter au Panier </> }
                        </Button>
                      )
                    }
                  </div>

                  <div className="col-lg-6">
                    <Link className="btn btn-outline-secondary btn-block btn-sm " to='/products'>
                      <Icofont icon="arrow-left"/>&nbsp;
                      Retour à la liste
                    </Link>
                  </div>
                </Row>

              </div>
            </>
          }

        </Row>
      </Container>
    </main>
  );
}

export default ShowProduct;