import Image from './Image';
import { Badge } from 'react-bootstrap';

const ShowImage = ({ images }) => {
  // console.log(images);
  const show = (image, key) => {
    return <><Badge pill bg="secondary">{++key}</Badge> <Image image={image} /> </>;
  };
  return <div className="container">{images.map(show)}</div>; 
};
export default ShowImage;