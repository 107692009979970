import { Container, Nav, Card, Row, Table, Image, Alert} from "react-bootstrap";
import { Link } from "react-router-dom"; 
import Icofont from "react-icofont";
import bannerRes from "../../assets/img/R-cover.png";

import valdelia from "../../assets/img/logo-valdelia.png";
import recyclivres from "../../assets/img/logo-recyclivre.png";
import ecomaison from "../../assets/img/logo-ecomaison.png";
import ecologic from "../../assets/img/logo-ecologic.png";

import "./About.css";

const Ressourcerie = (props) => {
  return (
    <main className="main-content mt-2">
        <div className="banner">
          <Image src={bannerRes} className="w-100"/>
        </div>
      <section className="section-pagetop bg fres">
        <Container>
          <h2 className="title-page">La Ressourcerie</h2>
          <Row>
            <div className="col-lg-8">
              <h3>Qu'est ce que la Ressourcerie ? </h3>
              <p>Notre premier objectif est de permettre l'accès à l'emploi. La Ressourcerie est une structure d'insertion liant l'emploi, la formation, l'accompagnement socio professionnel et l'occupation d'un emploi à durée déterminée.</p>
              <blockquote class="blockquote mb-0">
                <p><strong>C'est un tremplin vers l'emploi durable en entreprise.</strong></p>
              </blockquote>
              
              <h4>Le magasin</h4>
              <p>La Ressourcerie, c'est aussi un magasin de 1200m² qui propose des meubles, de l'électroménagers des bibelots, de la vaisselle ou des livres issus du réemploi. </p>
            </div>
            <div className="col-lg-4">
              <h3>Les Eco organismes</h3>
              <div className="justify-content-around">
                <Image src={ecomaison} width="100" />
                <Image src={ecologic} width="100" />
                <Image src={recyclivres} width="100" />
                <Image src={valdelia} width="100" />
              </div>
            </div>
          </Row>
          <Row>
            <Row className="justify-content-around col-lg-8">
              <h3>Nos services</h3>
              <Card className="col-lg-5 m-2" id="enc">
                <Card.Body>
                  <div>
                    <h4>Les encombrants</h4>
                    <p>Nous réalisons à raison d'une fois par mois la collecte des encombrants dans toutes les villes de l'agglomération Grand Calais Terres et Mers. Ce sont les habitants qui en font la demande. </p>  
                  </div>
                </Card.Body>
              </Card>

              <Card className="col-lg-5 m-2" id="cad">
                <Card.Body>
                  <div>
                    <h4>La collecte à domicile</h4>
                    <p>Sur demande de particulier ou d'entreprise nous nous déplaçons sur les lieux indiqués pour récupérer les dons, qui serviront à alimenter le magasin de la Ressourcerie ou qui partirons en relooking. </p>  
                  </div>
                </Card.Body>         
              </Card>

              <Card className="col-lg-5 m-2" id="cad">
                <Card.Body>
                  <div>
                    <h4>Les débarras</h4>
                    <p>A la demande d'agences immobilières, de notaires ou de particuliers nous vidons tout ou parti d'un logement.</p> 
                    <Alert variant="primary">   
                      <Icofont icon="phone-circle" /> &nbsp;
                      Demandez un devis au 07.78.80.09.53
                    </Alert>
                  </div>
                </Card.Body>         
              </Card>
            </Row>

            <Row className="col-lg-4">
              <h3>Horaires</h3>
              <Card>
                <Card.Body>
                  <Table hover>
                    <thead>  
                      <h4 style={{ fontSize: "1.4rem" }}>Horaires Magasin</h4>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Lundi</td>
                        <td>Fermé</td>
                      </tr>
                      <tr>
                        <td>Mardi</td>
                        <td>
                          <tr>09h30 - 12h30</tr> 
                          <tr>14h00 - 17h30</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Mercredi</td>
                        <td>
                          <tr>09h30 - 12h30</tr> 
                          <tr>14h00 - 17h30</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Jeudi</td>
                        <td>Fermé</td>
                      </tr>
                      <tr>
                        <td>Vendredi</td>
                        <td>
                          <tr>09h30 - 12h30</tr> 
                          <tr>14h00 - 17h30</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Samedi</td>
                        <td>
                          <tr>09h30 - 12h30</tr> 
                          <tr>14h00 - 17h30</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Dimanche</td>
                        <td>Fermé</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Row>
            {/* <Row>
              <h3>Nos produits</h3>
            </Row> */}
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Ressourcerie;