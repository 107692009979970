import { useEffect, useState } from "react";
import { Alert, Card, Container, Nav, Row } from "react-bootstrap";
import Icofont from "react-icofont";
import { Link } from "react-router-dom"; 

import authService from "../../services/auth.service";
import checkoutService from "../../services/checkout.service";

const Account = () => {
  const [user, setUser] = useState([]);
  const [orders, setOrders] = useState([]);

  const id = authService.getCurrentUser();

  useEffect(() => {
    const currentUser = authService.getUserData(id, setUser);
  }, [id]) 

  useEffect(() => {
    const orderList = checkoutService.getUserOrders(id, setOrders);
  }, [id])

  return (
    <div>
      <section className="section-pagetop bg">
        <Container>
          <h2 className="title-page">Mon compte</h2>

          <Row className="account">
            <Card className="col-4 m-2">
              <Card.Body>
                <Card.Title>
                  Mes informations
                </Card.Title>
                {user !== undefined ?(
                  <Row>
                    <>
                      <Card.Subtitle> Prénom </Card.Subtitle>
                      <Card.Text> {user.firstName }</Card.Text>

                      <Card.Subtitle> Nom </Card.Subtitle>
                      <Card.Text> {user.lastName }</Card.Text>

                      <Card.Subtitle> Email </Card.Subtitle>
                      <Card.Text> {user.email }</Card.Text>

                      <Card.Subtitle> Adresse </Card.Subtitle>
                      <Card.Text> {user.adress }</Card.Text>

                      <Card.Subtitle> Code postal </Card.Subtitle>
                      <Card.Text> {user.zipCode }</Card.Text>

                      <Card.Subtitle> Ville </Card.Subtitle>
                      <Card.Text> {user.city }</Card.Text>

                      <Card.Subtitle> Autorisations </Card.Subtitle>
                        {user.roles &&
                          user.roles.map((role, index) => 
                            role === 'ROLE_ADMIN' ? (
                            <Card.Text key={index}>Administrateur</Card.Text>
                            ) : (
                              role === 'ROLE_USER' ? (
                                <Card.Text key={index}>Utilisateur</Card.Text>
                              ) : <Card.Text key={index}>{role}</Card.Text>
                            )
                          )
                        }
                    </>
                  </Row>
                  ) : (
                    <Alert variant="alert alert-danger">
                      <Icofont icon="close-circled" /> Aucune information !
                    </Alert>
                  )
                }
                
              </Card.Body>
            </Card>

            <Card className="col-5 m-2">
              <Card.Body>
                <Card.Title>
                  Mes commandes
                </Card.Title>
                {orders !== undefined ? (
                  <Row>
                    {orders.map((order) => ( 
                      <Card className="m-1 blue">                          
                        <Card.Body>
                          <Card.Subtitle> Numéro </Card.Subtitle>
                          <Card.Text> {order.number }</Card.Text>

                          <Card.Subtitle> Statut </Card.Subtitle>
                          <Card.Text> {order.status }</Card.Text>

                          <Card.Subtitle> Date </Card.Subtitle>
                          <Card.Text> {order.createdAt }</Card.Text>

                          <Card.Subtitle> Facture </Card.Subtitle>
                          {/* <Card.Text> {order.bill.id }</Card.Text> */}
                        </Card.Body>
                      </Card>
                    ))}
                  </Row>
                  ) : (
                    <Alert variant="alert alert-danger">
                      <Icofont icon="close-circled" /> Aucune commande !
                    </Alert>
                  )
                }
                
              </Card.Body>
            </Card> 
            
            {user !== undefined &&
              <div className="col-2 m-2">
                <Link className="btn btn-primary mb-2" to={{ pathname: '/user/' + id +'/edit', state:user }}>
                  <Icofont icon="pencil"/> Modifier mes informations
                </Link>
                <Link className="btn btn-primary" to={{ pathname: '/user/' + id +'/editpassword', state:user }}>
                  <Icofont icon="lock"/> Modifier mon mot de passe
                </Link>
              </div>
            } 
          </Row>

        </Container>
      </section>
    </div>
  );
};

export default Account;