import axios from "axios";
import jwt_decode from "jwt-decode";

/**
 * 
 * @param {*} email 
 * @param {*} plainPassword 
 * @param {*} firstName 
 * @param {*} lastName 
 * @param {*} adress 
 * @param {*} zipcode 
 * @param {*} city
 * Create user object
 * @returns user
 */
const register = (email, plainPassword, firstName, lastName, adress, zipcode, city) => {
  return axios.post("users", {
    email,
    plainPassword, 
    firstName,
    lastName, 
    adress, 
    zipcode, 
    city
  });
};

export function verifyToken(){
  const token = localStorage.getItem('user');
  
  const decoded = jwt_decode(token);

  const now = new Date().getTime();
  const endTime = decoded.exp*1000; 
  
  if (endTime < now) {
    console.log("Token expired");
    return false
  } else {
    console.log("Valid token");  
    return true
  }
}

/**
 * @param email 
 * @param password
 * Get current user Token
 * @returns token 'user' 
 */
const login = (email, password) => {
  return axios({
      method: "post",
      url: `/login`,
      headers: {
        'content-type': 'application/json',
      },
      data: {
        email: email, 
        password: password
      } 
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.token));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem('user');
};

/**
 * Get current user id from token
 * @returns integer Id, array Roles 
 */
const getCurrentUser = () => {
  const token = localStorage.getItem('user');
  const id = jwt_decode(token).id;
  return id;
};

/**
 * Get current user data from token
 * @param id current user id
 * @returns array User 
 */
const getUserData = (id, setUser) =>{

  // const token = localStorage.getItem('user');
  // const id = jwt_decode(token).id;
    
  axios({
    method: "get",
    url: `/users/${id}`,
    headers: {  
      'Authorization':'Bearer '+ (localStorage.getItem('user')).replace(/"/g, '')
    }
  })
  .then(res => {
    const user = {
      email: res.data.email,
      firstName: res.data.firstName,
      lastName: res.data.lastName,
      adress: res.data.adress,
      zipCode: res.data.zipCode,
      city: res.data.city,
      roles: res.data.roles
    }
    setUser(user)
  })
  .catch(err =>{
    return err;
  })
  return setUser;
};

const getUserRoles = () => {
  const token = localStorage.getItem('user').replace(/"/g, '');
  const decoded = jwt_decode(token);
  console.log(decoded);
  return decoded.roles;
}

export default {
  register,
  login,
  logout,
  verifyToken,
  getCurrentUser,
  getUserData, 
  getUserRoles
};