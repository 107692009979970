import React,{ useEffect, useState } from "react";
import { Container, Row, Image, Card, ButtonGroup, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom"; 
import Icofont from "react-icofont";
import axios from "axios";

import defaultImg from "../../assets/img/default.png";

const Bill = () => {

  const param = useParams();

  const [bill, setBill] = useState([]);
  const [loading, setLoading] = useState(true);

  const getBill = useEffect(() => {
    axios({
      method: "get",
      url: `bills/${param.id}`,
      headers: {
        'content-type': 'application/json',
        'Authorization':'Bearer '+ (localStorage.getItem('user')).replace(/"/g, '') 
      }
    })
    .then((response) => {
      setBill(response.data);
      setLoading(false);
      console.log(bill);
    }).catch((error) => {
      console.log(error);
    })
  }, [loading])

  if(loading) {
    return (
      <Button className="col-lg-2 mx-auto">
        <span className="spinner-border spinner-border-sm"></span>
        <span> Chargement... </span>   
      </Button>
    )
  }

  return (
    <main className="main-content section-pagetop bg">
      <section className="section-pagetop">
        <Container>
          <h2 className="title-page">Votre facture</h2> 
          <Row className="m-2">
            {bill !== undefined &&
              <Card>
                <Card.Body>
                  <h4>Facture n° {bill.number} </h4>
                  <p>En date du {bill.linkedOrder.createdAt}</p>
                  <p>
                    {bill.linkedOrder.user.firstName} {bill.linkedOrder.user.lastName}
                    <br /> {bill.linkedOrder.user.adress}
                    <br /> {bill.linkedOrder.user.zipCode} {bill.linkedOrder.user.city}
                    <br /> {bill.linkedOrder.user.email}
                  </p>
                  <h5>Votre commande</h5>
                  <table className="table table-borderless table-shopping-cart">
                    <thead className="text-muted">
                      <tr className="small text-uppercase">
                        <th scope="col" width="120">Produit</th>
                        <th scope="col" width="120">Nom</th>
                        <th scope="col" className="text-right" width="120">Prix</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bill.linkedOrder.products.map((prod) => (
                        <tr>
                          <td style={{ verticalAlign: 'middle' }}>
                          {prod.media !== null ?
                            <Image src={prod.media[0].filePath} alt={prod.name} className="img-sm" style={{ maxWidth: '100px' }}/>
                          : 
                            <Image src={defaultImg} className="w-100" style={{ maxWidth: '100px' }} />
                          }
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            <h4>{prod.name}</h4>
                          </td>
                          <td style={{ verticalAlign: 'middle' }}> 
                            <ButtonGroup vertical>
                              <var className="price">{ prod.price  } €</var>
                            </ButtonGroup>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <h5>
                    Total: {bill.total} €
                  </h5>

                  <h5>Note complémentaire</h5>
                  <p>
                    Votre commande sera récupérable au 147 Boulevard Victor Hugo, 62100 Calais dans les 48h ouvrés. 
                  </p>
                    
                </Card.Body>
              </Card> 
            }
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Bill;