import React, {useState, useEffect, useRef} from "react";
import { Container,Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom"; 
// import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import axios from "axios";

const EditRoles = () => {

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger mt-1" role="alert">
          Ce champ est obligatoire !
        </div>
      );
    }
  };

  let navigate = useNavigate();
  const EditRolesForm = useRef();
  const checkRolesBtn = useRef();
  const param = useParams();

  const [roles, setRoles] = useState([]);
  const [newRoles, setNewRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const getUserData = useEffect(() => {
    axios({
      method: "get",
      url: `users/${param.id}`,
      headers: {
        'Authorization':'Bearer '+ (localStorage.getItem('user')).replace(/"/g, '')
      }
    })
    .then((response) => {
      setRoles(response.data.roles);
    }).catch((error) => {
      console.log(error);
    })
  }, [param.id])

  const handleRolesChange = (e) => {
    e.preventDefault();
    setMessage("");

    const data = {
      roles: newRoles 
    }
  
    axios({
      method: "patch",
      url: `/users/${param.id}`,
      data: [data.roles],
      headers: {  
        'Accept': "application/json",
        'Content-Type': 'application/merge-patch+json',
        'Authorization': 'Bearer '+ (localStorage.getItem('user')).replace(/"/g, ''),
      }
    })
    .then(response => {
      console.log(response.data);
      setMessage(response.data.message);
      navigate('/user/index'); 
      // window.location.reload();
    })
    .catch(err => {
      console.log("Une erreur est survenue :" + err)
    })
  } 

  return (
    <main>
      <div className="main-content section-pagetop bg mt-1">
        <section className="padding-y mt-2">             
          <Container>
            <div className="card mx-auto" style={{ maxWidth: '420px', marginTop: '100px' }}>
              <div className="card-body">
                <h4 className="card-title mb-4">Modifier les permissions</h4>
                <Form onSubmit={handleRolesChange}>

                {roles !== undefined &&
                  <>
                    <Form.Group>
                      <Form.Label>Permission actuelle</Form.Label>
                      {roles[0] === 'ROLE_USER' ?
                        <p>Utilisateur</p>
                        : roles[0] === 'ROLE_EDITOR' ? 
                        <p>Editeur</p> 
                        : roles[0] === 'ROLE_ADMIN' ? 
                        <p>Administrateur</p> : ''
                      }
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Sélectionner un rôle</Form.Label>
                      <Form.Select onChange={e => setNewRoles(e.target.value)} validations={[required]}> 
                        <option value="ROLE_USER">Utilisateur</option>
                        <option value="ROLE_EDITOR">Editeur</option>
                        <option value="ROLE_ADMIN">Administrateur</option>
                      </Form.Select>
                    </Form.Group>
                  </>

                }

                  <div className="form-group mt-2">
                    <button className="btn btn-primary btn-block" disabled={loading}>
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Valider</span>
                    </button>
                  </div>

                  {message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    </div>
                  )}

                  <div className="form-group">
                    <Link to="/user/index" className="float-right m-2">Retour à la liste</Link> 
                  </div>
                </Form>
              </div> 
            </div>              
          </Container>              
        </section>
      </div>
    </main>
  );
};
  
export default EditRoles;