import React, { useState, useEffect, useRef} from "react";
import { Container } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom"; 
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import axios from "axios";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Ce champ est obligatoire
      </div>
    );
  }
};
  
const UserEdit = (props) => {

  const param = useParams();

  const editForm = useRef();
  const editCheckBtn = useRef();

  let navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [adress, setAdress] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState(0);

  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");

  const getUserData = useEffect(() => {
    axios({
      method: "get",
      url: `users/${param.id}`,
      headers: {
        'Authorization':'Bearer '+ (localStorage.getItem('user')).replace(/"/g, '')
      }
    })
    .then((response) => {
      setFirstName(response.data.firstName);
      setLastName(response.data.lastName);
      setEmail(response.data.email);
      setAdress(response.data.adress);
      setCity(response.data.city);
      setZipCode(response.data.zipCode);
    }).catch((error) => {
      console.log(error);
    })
  }, [param.id])

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangeFirstName = (e) => {
    const firstName = e.target.value;
    setFirstName(firstName);
  };

  const onChangeLastName = (e) => {
    const lastName = e.target.value;
    setLastName(lastName);
  };

  const onChangeAdress = (e) => {
    const adress = e.target.value;
    setAdress(adress);
  };

  const onChangeZipCode = (e) => {
    const zipCode = e.target.value;
    setZipCode(zipCode);
  };

  const onChangeCity = (e) => {
    const city = e.target.value;
    setCity(city);
  };

  const handleEdit = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    editForm.current.validateAll();

    if (editCheckBtn.current.context._errors.length === 0) {

      const editForm = e.currentTarget;
      if (editForm.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
  
      e.preventDefault();
  
      const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        adress: adress,
        city: city,
        zipCode: zipCode,
      }
  
      axios({
        method: "patch",
        url: `/users/${param.id}`,
        data: data,
        headers: {  
          'Accept': "application/json",
          'Content-Type': 'application/merge-patch+json',
          'Authorization': 'Bearer '+ (localStorage.getItem('user')).replace(/"/g, ''),
          // 'Access-Control-Allow-Origin': '^http?://(localhost|127\.0\.0\.1)(:[0-9]+)?$'
        }
      })
      .then(response => {
        setMessage(response.data.message);
        setSuccessful(true);
        navigate('/account'); 
        window.location.reload();
      })
      .catch(err => {
          const resMessage =
            (err.response &&
              err.response.data &&
              err.response.data.message) ||
            err.message ||
            err.toString();

          setMessage(resMessage);
          setSuccessful(false);
        console.log("Une erreur est survenue :" + err)
      })
      
    } else {
      setMessage('Une erreur est survenue, veuillez rééssayer.');
    }
  }

  return (
    <main>
      <div className="main-content section-pagetop bg mt-1">  
        <div>
          <Container>
            <div className="card mx-auto" style={{ maxWidth: '520px'}}>
              <article className="card-body">
                <header className="mb-4">
                  <h4 className="card-title">Modifier mes informations</h4>
                </header>
                <Form onSubmit={handleEdit} ref={editForm}>
                  {!successful && (
                    <>
                      <div className="form-row">
                        <div className="col form-group">
                          <label>Prénom</label>
                            <Input type="text" className="form-control" value={firstName} onChange={onChangeFirstName} validations={[required]} />
                        </div>
                        <div className="col form-group">
                          <label>Nom</label>
                            <Input type="text" className="form-control" value={lastName} onChange={onChangeLastName} validations={[required]} />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Email</label>
                        <Input type="email" className="form-control" value={email} disabled={true} onChange={onChangeEmail} validations={[required]} />
                      </div>
                      <div className="form-group">
                        <label>Adresse</label>
                        <Input type="text" className="form-control" value={adress} onChange={onChangeAdress} validations={[required]} />
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>Ville</label>
                          <Input type="text" className="form-control" value={city} onChange={onChangeCity} validations={[required]}  />
                        </div>
                        <div className="form-group col-md-6">
                          <label>Code postal</label>
                          <Input className="form-control" value={zipCode} onChange={onChangeZipCode} validations={[required]} />
                        </div>
                      </div>
                      <div className="form-group mt-2">
                        <button type="submit" className="btn btn-primary btn-block"> Modifier </button>
                      </div>     
                    </>
                  )}  
                  {message && (
                    <div className="form-group">
                      <div
                        className={ successful ? "alert alert-success" : "alert alert-danger" }
                        role="alert"
                      >
                        {message}
                      </div>
                    </div>
                  )}
                  <CheckButton style={{ display: "none" }} ref={editCheckBtn} />       
                </Form>
              </article>
            </div>
          </Container>
        </div>
      </div>
    </main>
  );
};

export default UserEdit;