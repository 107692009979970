import react, {useState, useEffect} from 'react';
import axios from 'axios';

import { CartState } from '../../context/Context';

import defaultImg from "../../assets/img/default.png";
import { Button, Image, ButtonGroup, ButtonToolbar, Form, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icofont from 'react-icofont';

// methods
// import { verifyToken } from "../../utils/helper";

export default function UserIndex() {

    const [users, setUsers] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false)
    const [isUser, setIsUser] = useState(false)

    const getUsers = useEffect(() => {
      axios({
        method: "get",
        url: "users/",
        headers: {  
          'Authorization':'Bearer '+ localStorage.getItem("user").replace(/"/g, '')
        }
      })
      .then((response) => {
        setUsers(response.data);
        // console.log(users)
      }).catch((error) => {
        // console.log(error);
      })
    }, [])   

  return (
  
    <section className="section-pagetop bg">
      <div className="container">
        <div className="row">
          <main className="col-12">
            <div className="card">
              <table className="table table-borderless table-shopping-cart">
                <thead className="text-muted">
                  <tr className="small text-uppercase">
                    <th scope="col" width="120">Prénom</th>
                    <th scope="col">Nom</th>
                    <th scope="col">Email</th>
                    <th scope="col">Roles</th>
                    <th scope="col" width="120">Adresse</th>
                    <th scope="col" width="120">Code postal</th>
                    <th scope="col" width="120">Ville</th>
                    <th scope="col" className="text-right" width="200"> Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users !== undefined && users.length > 0 ? (
                    <>
                      {
                        users.map((user) => (
                        <tr>
                          <td style={{verticalAlign: 'middle'}}>
                            {user.firstName}
                          </td>
                          <td style={{verticalAlign: 'middle'}}> 
                            {user.lastName}
                          </td>
                          <td style={{verticalAlign: 'middle'}}> 
                            {user.email}
                          </td>
                          <td style={{verticalAlign: 'middle'}}> 
                            {user.roles}
                          </td>
                          <td style={{verticalAlign: 'middle'}}>
                            {user.adress}
                          </td>
                          <td style={{verticalAlign: 'middle'}}>
                            {user.zipCode} 
                          </td>
                          <td style={{verticalAlign: 'middle'}}>
                            {user.city} 
                          </td>
                          <td style={{verticalAlign: 'middle'}}>
                          <ButtonToolbar>
                            <ButtonGroup>
                              <Link to={{ pathname: '/user/' + user.id +'/edit_roles', state:user }} className="m-1 btn btn-sm btn-warning">
                                <Icofont icon="pencil" />
                              </Link>
                            </ButtonGroup>
                            {/* <ButtonGroup>
                              <Link to={{ pathname: '/user/' + user.id +'/edit', state:user }} className="m-1 btn btn-sm btn-info">
                                <Icofont icon="eye" />
                              </Link>
                            </ButtonGroup> */}
                            <ButtonGroup>
                              <Link to={{ pathname: '/user/' + user.id +'/delete', state:user }} className="m-1 btn btn-sm btn-danger"> 
                                <Icofont icon="bin" /> 
                              </Link>
                            </ButtonGroup>  
                          </ButtonToolbar>
                          </td>
                        </tr>
                        ))
                      }
                    </>
                  ) : (<tr style={{textAlign:'center'}}>Aucun utilisateur trouvé !</tr>)}
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div> 
    </section>
  );
};