import React, {useState, useEffect} from "react";
import { Link, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { Image, Navbar, Nav, Container, Button, DropdownButton, Dropdown, Badge } from 'react-bootstrap';
import Icofont from 'react-icofont';

// assets
import logo from "../../assets/img/logofv.png";
import './Navbar.css';

// components
import Cart from "../../components/cart/Cart";
import Product from "../../components/product/Product";
import ProductIndex from "../product/ProductIndex";
import AddProduct from "../../components/product/AddProduct";
import Products from "../product/Products";

import AddCategory from "../../components/category/AddCategory";
import CategoryIndex from "../category/CategoryIndex";

import UserIndex from "../user/UserIndex";
import OrderIndex from "../order/OrderIndex";

import Login from "../authentication/Login";
import Register from "../authentication/Register";
import Account from "../user/Account";
import ForgotPassword from "../authentication/ForgotPassword";
import ResetPassword from "../authentication/ResetPassword";

import Ressourcerie from "../about/Ressourcerie";
import Materiautheque from "../about/Materiautheque";
import Professionnel from "../about/Professionnel";
import Aidotec from "../about/Aidotec";
import Vuedenface from "../about/Vuedenface";
import RessMatMed from "../about/RessourcerieMatMed";

import LegalNotice from "../disclaimer/LegalNotice";
import TermsOfSales from "../disclaimer/TermsOfSales";

// pages
import Home from "../../pages/Home";

// context
import { CartState } from "../../context/Context";
import { Elements } from "@stripe/react-stripe-js";

import Facebook from "../utils/Facebook";

import authService from "../../services/auth.service";
import { CheckoutForm } from "../checkout/Checkout";
import Other from "../about/Other";
import Bill from "../bill/Bill";
import Payment from "../checkout/Payment";
import OrderSuccess from "../checkout/OrderSuccess";
import ShowProduct from "../product/ShowProduct";
import EditProduct from "../product/EditProduct";
import DeleteProduct from "../product/DeleteProduct";
import UserEdit from "../user/UserEdit";
import EditPassword from "../user/EditPassword";
import EditRoles from "../user/EditRoles";
import DeleteCategory from "../category/DeleteCategory";

// stripe
const MainNavbar = ({currentUser, isAdmin, setIsAdmin}) => {

  let navigate = useNavigate();
  const [total, setTotal] = useState(0);

  const {
    state : { cart },
    dispatch
  } = CartState();

  const logout = () => {
    authService.logout();
    navigate('/');
    window.location.reload();
  };

  useEffect(() => {
    setTotal(
      cart.reduce((acc, curr) => acc + Number(curr.price) * curr.qty, 0)
    );
  }, [cart]);

  useEffect(() => {
    if(currentUser){
      if (currentUser.roles.includes('ROLE_ADMIN')){
        setIsAdmin(true);
      }
    }
  }, [currentUser]);

  // console.log(currentUser);

  return (
    <header className="section-header">
      <section className="header-main border-bottom">
        <Container>
          <div className="row align-items-center">
            <div className="col-lg-2 col-6">
              <Navbar.Brand> 
                <Image src={logo} width={150}></Image>
              </Navbar.Brand>
            </div>
            {/* <div className="col-lg-6 col-12 col-sm-12">
              <form action="#" className="search">
                <div className="input-group w-100">
                  <input type="text" className="form-control" placeholder="Rechercher" />
                  <div className="input-group-append">
                    <button className="btn" type="submit" style={{background: 'rgb(41, 137, 143)', color:'#fff'}}>
                      <Icofont icon="search-2" />
                    </button>
                  </div>
                </div>
              </form> 
            </div>  */}
            <div className="col-lg-6 col-12 col-sm-12">
            </div> 
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="widgets-wrap float-md-right">
                {/* <div className="widget-header mr-3">
                  <Link to="/cart" className="icon icon-sm rounded-circle border">
                    <Icofont icon="cart" />
                  </Link> 
                  {cart !== undefined && cart.length > 0 ? 
                    <Badge className="badge badge-pill badge-danger notify" bg="danger">{cart.length}</Badge> : ''
                  }
                </div> */}
                <div className="widget-header icontext">
                  {currentUser ? (
                    <Link to="/account" className="icon icon-sm rounded-circle border mr-3">
                      <Icofont icon="user"/>
                    </Link> 
                    ) : ( 
                      <Link to="/login" className="icon icon-sm rounded-circle border mr-3">
                        <Icofont icon="user"/>
                      </Link> 
                    )
                  }
                  {currentUser ? (
                    <div className="text">
                      <span className="text-muted">{currentUser.email}</span>
                      <div> 
                        <Link to="" onClick={logout}> Déconnexion</Link> 
                      </div>
                    </div>   
                    ) : (
                      <div className="text">
                        <span className="text-muted">Bienvenue !</span>
                        <div> 
                          <Link to="/login">Connexion</Link>   
                          {/* <Link to="/register">| Inscription</Link> */}
                        </div>
                      </div>
                    )
                  }
                </div>
              </div> 
            </div> 
          </div> 
        </Container> 
      </section> 
      <Navbar expand="lg" sticky="top" className="navbar navbar-main navbar-expand-lg navbar-light border-bottom" style={{ background: '#023976' }}>
        <Nav expand="lg">
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="navbar-nav">
                <Nav.Link className="nav-item">
                  <Link to="/" className="nav-link">Accueil</Link>
                </Nav.Link>
                <Nav.Link className="nav-item">
                  <Link to="/ressourcerie" className="nav-link">La Ressourcerie</Link>
                </Nav.Link>
                <Nav.Link className="nav-item">
                  <Link to="/materiautheque" className="nav-link">La Matériauthèque</Link>
                </Nav.Link>
                <Nav.Link className="nav-item">
                  <Link to="/professionnel" className="nav-link">Le Mobilier Professionnel</Link>
                </Nav.Link>
                <Nav.Link className="nav-item">
                  <Link to="/aidotec" className="nav-link">AIDOTEC</Link>
                </Nav.Link>
                <Nav.Link className="nav-item">
                  <Link to="/other" className="nav-link">Relooking</Link>
                </Nav.Link>
                <Nav.Link className="nav-item">
                  <Link to="/vuedenface" className="nav-link">Vue d'en FACE</Link>
                </Nav.Link>
                <Nav.Link className="nav-item">
                  <Link to="/ressourceriematerielmedical" className="nav-link">Ressourcerie du matériel médical</Link>
                </Nav.Link>
              </Nav>
              <Nav className="justify-content-end">
                {/* <Nav.Link className="nav-item">
                  <Link to="/candidate" className="nav-link">Candidathèque</Link>
                </Nav.Link> */}
                {/* <Nav.Link className="nav-item">
                  <Link to="/products" className="nav-link btn btn-primary" style={{ display: 'flex' }}><Icofont icon="cart"/>&nbsp; Nos produits</Link>
                </Nav.Link> */}

                {isAdmin &&
                  <DropdownButton className="nav-link" id="input-group-dropdown-1" variant="primary" title="Administration" style={{ display: 'flex' }}>
                    <Dropdown.Item>
                      <Nav.Link className="nav-item">
                        <Link to="/product/add" style={{color: '#000'}}>Ajouter un produit</Link>
                      </Nav.Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Nav.Link className="nav-item">
                        <Link to="/product/index" style={{color: '#000'}}>Liste des produits</Link>
                      </Nav.Link>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item>
                      <Nav.Link className="nav-item">
                        <Link to="/order/index" style={{color: '#000'}}>Liste des commandes</Link>
                      </Nav.Link>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item>
                      <Nav.Link className="nav-item">
                        <Link to="/user/index" style={{color: '#000'}}>Liste des utilisateurs</Link>
                      </Nav.Link>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item>
                      <Nav.Link className="nav-item">
                        <Link to="/category/add" style={{color: '#000'}}>Ajouter une catégorie</Link>
                      </Nav.Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Nav.Link className="nav-item">
                        <Link to="/category/index" style={{color: '#000'}}>Liste des catégories</Link>
                      </Nav.Link>
                    </Dropdown.Item>
                  </DropdownButton>
                }
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Nav>

      </Navbar>
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ressourcerie" element={<Ressourcerie />}/>
        <Route path="/professionnel" element={<Professionnel />}/>
        <Route path="/materiautheque" element={<Materiautheque />}/>
        <Route path="/aidotec" element={<Aidotec />}/>
        <Route path="/vuedenface" element={<Vuedenface />} />
        <Route path="/ressourceriematerielmedical" element={<RessMatMed />} />

        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Payment total={total} />} />
        <Route path="/order/success" element={<OrderSuccess />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/account" element={<Account />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/reset_password" element={<ResetPassword /> } />
        <Route path="/products" element={<Products />} />
        <Route path="/product/:id" element={<ShowProduct />} />
        <Route path="/product/add" element={<AddProduct/>} />
        <Route path="/product/:id/edit" element={<EditProduct/>} />
        <Route path="/product/index" element={<ProductIndex/> } />
        <Route path="/product/:id/delete" element={<DeleteProduct/>} /> 
        <Route path="/bill/:id" element={<Bill/>} />
        <Route path="/other" element={<Other />} />
        <Route path="/disclaimer" element={<LegalNotice />} />
        <Route path="/termsofsales" element={<TermsOfSales />} />
        <Route path="/category/add" element={<AddCategory/>} />
        <Route path="/category/index" element={<CategoryIndex />} />
        <Route path="/category/:id/delete" element={<DeleteCategory />} />
        <Route path="/order/index" element={<OrderIndex />} />
        <Route path="/user/index" element={<UserIndex />} />
        <Route path="/user/:id/edit" element={<UserEdit />} />
        <Route path="/user/:id/editpassword" element={<EditPassword />} />
        <Route path="/user/:id/edit_roles" element={<EditRoles />} /> 
        <Route path="/facebook" element={<Facebook />} />
        {/* <Route path="/logout" element={<Home logout={logout} />} /> */}
      </Routes>
    </header>
  );
};
export default MainNavbar;