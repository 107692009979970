import axios from "axios";
import { useState } from "react";

/**
 * Remove product from cart
 * @returns 200 
 */
const removeProductPostorder = (cart, dispatch) => {

  cart.forEach(prod => {
    let id = prod.id;

    axios({
      method: "patch",
      url: `/products/${id}`,
      headers: {  
        'Content-Type':'application/merge-patch+json',
        'Authorization':'Bearer '+ (localStorage.getItem('user')).replace(/"/g, '')
      }, 
      data: {
        quantity: Number(prod.quantity)-Number(prod.qty)
      }
    })
    .then(res => {
      // console.log(res);
    })
    .catch(err =>{
      // console.log(err);
    })

    dispatch({
      type:"REMOVE_FROM_CART",
      payload: prod
    })
  })
};

const getProductsInCart = (cart) => {
  let productOrdered = [];
  
  cart.forEach(prod => {
    productOrdered.push('/api/products/' + prod.id);
    console.log(productOrdered);
  })
  return productOrdered;
}
  
export default {
  removeProductPostorder,
  getProductsInCart
}