import axios from "axios";

const createOrder = (data) => {
  console.log(data);
  return axios({
    method: "post",
    url: "/orders",
    data: data,
    headers: {  
      'Authorization':'Bearer '+ (localStorage.getItem('user')).replace(/"/g, '')
    }
  })
};

const getUserOrders = (id, setOrders) => {
  axios({
    method: "get",
    url: `/users/${id}/orders?page=1`,
    headers: {  
      'Authorization':'Bearer '+ (localStorage.getItem('user')).replace(/"/g, '')
    }
  })
  .then(res => {
    const orders = res.data;
    setOrders(orders);
  })
  .catch(err =>{
    return err;
  })
  
  return setOrders();
}

const getUserBills = (id, setBills, setLoading) => {
  axios({
    method: "get",
    url: `/bills/${id}/linkedOrder?page=1`,
    headers: {  
      'Authorization':'Bearer '+ (localStorage.getItem('user')).replace(/"/g, '')
    }
  })
  .then(res => {
    const bills = res.data;
    setBills(bills);
    setLoading(false);
  })
  .catch(err =>{
    return err;
  })
  
  return (setBills(), setLoading());
};

const payment = (token, amount) => {
  axios({
    method: "post",
    url: `/order/payment/${token}/${amount}`,
  })
  .then(res => {
    console.log(res);   
    return res;
  })
  .catch(err => {
    console.log(err);
    return err;
  })
};

const createBill = (data) => {
  axios({
    method: 'post',
    url: '/bills', 
    headers: {
      'content-type': 'application/json',
      'Authorization':'Bearer '+ (localStorage.getItem('user')).replace(/"/g, '')
    }, 
    data: data
  })
  .then(res => {
    console.log(res)
  })
  .catch(err => {
    console.log(err)
  })
}

const getLastOrderId = (setLastOrder) => {
  axios({
    method: 'get',
    url: '/orders/last',
    headers: {  
      'content-type': 'application/json',
      'Authorization':'Bearer '+ (localStorage.getItem('user')).replace(/"/g, '')
    }
  })
  .then(res => {
    // console.log(res)
    const lastOrder = {
      id: res.data.id
    }
    setLastOrder(lastOrder.id)
  })
  .catch(err => {
    return err;
  })
}

export default {
  createOrder,
  getUserOrders,
  getUserBills,
  payment,
  createBill,
  getLastOrderId,
};