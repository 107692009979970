import axios from "axios";
import React, {useState} from "react";
import { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import Icofont from 'react-icofont';
import { Link } from "react-router-dom"; 

// assets
import "./Filter.css";

const Filter = ()  => {

  const [categories, setCategories] = useState([]);
  const [places, setPlaces] = useState([]);

  const getCategories = useEffect(() => {
    axios({
      method: "get",
      url: "categories/",
    })
    .then((response) => {
      setCategories(response.data);
      // console.log(categories)
    }).catch((error) => {
      // console.log(error);
    })
  }, [])   

  const getPlaces = useEffect(() => {
    axios({
      method: "get",
      url: "categories/"
    })
    .then((response) => {
      setPlaces(response.data);
      // console.log(places);
    }).catch((error) => {
      // console.log(error);
    })
  }, [categories])
  
  return (
    <Accordion defaultactivekey={['0']} alwaysOpen={true} className="col-md-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header className="filter-group">
          <h6 className="title">Catégorie</h6>
        </Accordion.Header> 
        <Accordion.Body>
            <div className="filter-content">
              <div>
                <form className="pb-3">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Rechercher"/>
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="button">
                        <Icofont icon="icofont-search-2" />
                      </button>
                    </div>
                  </div>
                </form>
                <ul className="list-menu">
                  {categories !== undefined && categories.length > 0 ? 
                    (categories.map((category) => (
                      <li><Link to="">{category.name}</Link></li>
                    ))) :
                    ''
                  }
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item> 
        <AccordionItem eventKey="1" className="filter-group">
          <AccordionHeader>
            <h6 className="title">Lieu de vente</h6>
          </AccordionHeader>
          <AccordionBody className="filter-content">
            <div className="card-body">
              <ul className="list-menu">
                {places !== undefined && places.length > 0 ? 
                  (places.map((place) => (
                    <li><Link to="">{place.place}</Link></li>
                  ))) :
                  ''
                }
              </ul>
            </div>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem eventKey="2" className="filter-group">
          <AccordionHeader>
            <h6 className="title">Prix</h6>
          </AccordionHeader>
          <AccordionBody className="filter-content">
            <div className="card-body">
              <input type="range" className="custom-range" min="0" max="1000" />
              <div className="form-row">
                <div className="form-group col-md-6 mb-1">
                  <label>Min</label>
                  <input className="form-control" placeholder="0" type="number"/>
                </div>
                <div className="form-group text-right col-md-6 mb-1">
                  <label>Max</label>
                  <input className="form-control" placeholder="1 000" type="number"/>
                </div>
              </div>
              <button className="btn btn-block btn-primary">Appliquer</button>
            </div>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};

export default Filter; 