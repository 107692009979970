import { Container, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom"; 

import SectionHeader from "../sectionheader/SectionHeader";

const LegalNotice = (props) => {
  const location = useLocation();

    return (
      <main>
        <SectionHeader title='Mentions Légales'/>
        <Container className="main-content mt-1">
          <section className="padding-y mt-2">
            <div className="container">

              <h6>Identité</h6>
              <p>FACEVALO LITTORAL NORD PAS DE CALAIS est une association déclarée du secteur de l'Insertion par l'Activité Economique, immatriculée sous le SIREN 	53407265700040 sise au 147 Boulevard Victor Hugo 62100 Calais. </p>

              <h6>Coordonnées</h6>
              <p>03.59.44.37.56</p>

              <h6>Hébergement</h6>
              <p>OVH Cloud</p>
              <p>2 rue Kellermann 59100 Roubaix</p>

            </div>
          </section>
        </Container>
      </main>
    );
  };
  
  export default LegalNotice;