import { useState } from "react";
import { Container, Form, InputGroup, Button } from "react-bootstrap";
import { Link, useNavigate  } from "react-router-dom"; 

import axios from "axios";

const AddCategory = () => {

  let navigate = useNavigate();

  const [name, setName] = useState();
  const [place, setPlace] = useState();

  const handleSubmit = e => {
    e.preventDefault();

    const data = {
      name: name, 
      place: place
    };

    axios({
      method: "post",
      url: "/categories",
      data: data,
      headers: {  
        'Authorization':'Bearer '+ (localStorage.getItem('user')).replace(/"/g, '')
      }
    })
    .then(res => {
      // console.log('Nouveau produit : ' + res.data);
      // navigate('Home');
    })
    .catch(err => {
      // console.log("Une erreur est survenue :" + err)
    })
  }

  return (
    <div>
      <section className="section-pagetop bg">
        <Container>
          <div className="card mx-auto" style={{ maxWidth: '520px'}}>
            <article className="card-body">
              <header className="mb-4">
                <h4 className="card-title">Ajouter une catégorie de produit</h4>
              </header>
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Nom</Form.Label>
                  <Form.Control type="text" className="form-control" onChange={e => setName(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Lieu</Form.Label>
                  <Form.Control type="text" className="form-control" onChange={e => setPlace(e.target.value)}/>
                </Form.Group>

                <div className="form-group mt-2">
                  <Button type="submit" className="btn btn-primary btn-block"> Ajouter  </Button>
                </div>              
              </Form>
            </article>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default AddCategory;