import axios from "axios";

const upload = (file) => {
  let formData = new FormData();
  formData.append("filePath", file);
  return axios.post("media/upload", formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization':'Bearer '+ (localStorage.getItem('user')).replace(/"/g, '')
    },
  });
};

const getFiles = (id) => {
  return axios.get(`/media/${id}`);
};

const FileUploadService = {
  upload,
  getFiles,
};

export default FileUploadService;