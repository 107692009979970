import React, { useEffect } from "react";

function Contact() {

  useEffect(() => {
    window.location.href = "https://www.facebook.com/La-Ressourcerie-du-Calaisis-FACE-Valo-1525909094323323";
  }, []);

  return (
    <>
    </>
  );
}

export default Contact;