import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import authService from "../../services/auth.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger mt-1" role="alert">
        Ce champ est obligatoire !
      </div>
    );
  }
};

const Login = (props) => {

  let navigate = useNavigate();
  const form = useRef();
  const checkBtn = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      authService.login(email, password).then(
        () => {
          navigate('/account/')
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <main>
      <div className="main-content bg mt-1">  
        <section className="section-pagetop">
          <Container>
            <div className="card mx-auto" style={{ maxWidth: '380px', marginTop: '100px' }}>
              <div className="card-body">
                <h4 className="card-title mb-4">Connexion</h4>
                  <Form onSubmit={handleLogin} ref={form}>
                    <div className="form-group">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={onChangeEmail}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <Input
                        type="password"
                        placeholder="Mot de passe"
                        className="form-control"
                        autoComplete="new_password"
                        name="password"
                        value={password}
                        onChange={onChangePassword}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <button className="btn btn-primary btn-block" disabled={loading}>
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span> Connexion</span>
                      </button>
                    </div>

                  {message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <Link to="/forgot_password" className="float-right m-2">Mot de passe oublié</Link> 
                    {/* <label className="float-left custom-control custom-checkbox"> 
                      <input type="checkbox" className="custom-control-input" checked="" /> 
                      <div className="custom-control-label"> Se souvenir de moi </div> 
                    </label> */}
                  </div>

                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
              </div> 
            </div>

            <p className="text-center mt-4">Pas encore de compte ? <Link to="/register">Inscrivez-vous</Link></p>
          </Container>
        </section>
      </div>
    </main>
  );
};

export default Login;