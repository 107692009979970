import axios from "axios";
import React, {useState} from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import CategoryIndex from "./CategoryIndex";

import { Modal, Button } from "react-bootstrap";

const DeleteCategory = () => {

  const param = useParams();

  const [show, setShow] = useState(true);

  const handleClose = () => { setShow(false); navigate('/category/index'); window.location.reload();};
  const handleShow = () => setShow(true);

  let navigate = useNavigate();

  const handleDelete = e => {
    e.preventDefault();
      if(param){
        axios({
          method: "delete",
          url: `/categories/${param.id}`,
          headers: {  
            'Authorization':'Bearer '+ (localStorage.getItem('user')).replace(/"/g, '')
          }
        })
        .then(res => {
          setShow(false);
          navigate('/category/index');
          window.location.reload();
        })
        .catch(err => {
          // console.log(err);
        })
        setShow(false);
        navigate('/category/index');
        window.location.reload();
      } else {
        // console.log('Delete Error.');
      }
  }

  return (
    <main className="main-content">
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmer la suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="alert alert-danger">Etes vous sûr de vouloir supprimer cet objet? </div></Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </main>

  )
}

export default DeleteCategory;