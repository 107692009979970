export const cartReducer = (state, action) => {
  switch(action.type) {
    case 'FETCH_SUCCESS' :
      return {
        products: action.payload,
        cart: [...state.cart]
      }
    case 'FETCH_ERROR' :
      return {
        products : [],
        cart: [...state.cart]
      }
    case "ADD_TO_CART":
      return { 
        ...state, 
        cart: [...state.cart, { ...action.payload, qty: 1 }]
      };
    case "REMOVE_FROM_CART":
      return {
        ...state,
        cart: state.cart.filter((c) => c.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export const productReducer = (state, action) => {
  switch (action.type) {
    case "SORT_BY_STOCK":
      return { ...state, byStock: !state.byStock };
    case "FILTER_BY_CATEGORY":
      return { ...state, byCategory: !state.byCategory };
    case "FILTER_BY_PLACE":
      return { ...state, byPlace: !state.byPlace };
    case "SORT_BY_PRICE":
      return { ...state, sort: action.payload };
    case "FILTER_BY_SEARCH":
      return { ...state, searchQuery: action.payload };
    case "CLEAR_FILTERS":
      return { byStock: false, byCategory: false, byPrice: 0 };
    default:
      return state;
  }
};