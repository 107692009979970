import { Container, Nav, Row, Card, Table, Image, Alert } from "react-bootstrap";
import Icofont from "react-icofont";
import { Link } from "react-router-dom"; 
import bannerAid from "../../assets/img/banner-aid.png";
import "./About.css";

import aid6 from "../../assets/img/aid6.jpg";
import aid5 from "../../assets/img/aid5.jpg";


const Aidotec = () => {
  return (
    <main className="main-content mt-2">
      <div className="banner">
        <Image src={bannerAid} className="w-100"/> 
      </div>
      <section className="section-pagetop bg faid">
        <Container>
          <h2 className="title-page">Aidotec</h2>
          <Row className="col-8">
            <h3>Qu'est ce qu'AIDOTEC</h3>
            <p>AIDOTEC : <span>AI</span>de <span>D'O</span>ccasion <span>TEC</span>hnique est une filière de réemploi du matériel médical inutilisé. </p>
            <p>Pour plus de renseignements, rendez vous sur  <a href="https://aidotec.fr" className="btn btn-primary btn-sm"><Icofont icon="curved-right" /> aidotec.fr</a></p>
          </Row>

          <Row className="my-2">
            <Row className="justify-content-around col-lg-8">
              <h3>Nos services</h3>
              <Card className="col-lg-5 m-1" id="enc">
                <Card.Body>
                  <div>
                    <h4>Collecte de matériel médical</h4>
                    <p>Nous nous déplaçons dans tout le département du Pas-de-Calais pour récolter les dons de matériel médical.</p>  
                    <Image src={aid6} className="img-fluid"></Image>
                  </div>
                </Card.Body>
              </Card>
              <Card className="col-lg-5 m-1" id="enc">
                <Card.Body>
                  <div>
                    <h4>Réparation</h4>
                    <p>Les aides techniques collectées sont, selon leur état, démantelées pour pièces ou réparées pour être redistribuées. </p>  
                  </div>
                </Card.Body>
              </Card>
              <Card className="col-lg-5 m-1" id="enc">
                <Card.Body>
                  <div>
                    <h4>Désinfection</h4>
                    <p>Après réparation, le matériel est nettoyé et désinfecté dans le respect des normes en vigueur.</p>  
                  </div>
                </Card.Body>
              </Card>
              <Card className="col-lg-5 m-1" id="enc">
                <Card.Body>
                  <div>
                    <h4>Redistribution</h4>
                    <p>Nos aides techniques sont redistribuées aux bénéficiaires de l'APA et de la PCH. </p>  
                    <p>Pour plus d'informations, contactez nous au 07.50.63.19.47</p>
                    <Image src={aid5} className="img-fluid"></Image>

                  </div>
                </Card.Body>
              </Card>
            </Row>
            <Row className="col-lg-4 align-items-flex-start">
              <h3>Horaires & infos</h3>
              <Alert variant="primary">   
                <Icofont icon="phone-circle" /> &nbsp;
                Contactez nous au 07.50.63.19.47
              </Alert>
              <Card>
                <Card.Body>
                  <Table hover>
                    <thead>  
                      <h4 style={{ fontSize: "1.3rem"}}>Horaires FACE Valo</h4>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Lundi</td>
                        <td>
                          <tr>9h00 - 12h00</tr> 
                          <tr>13h30 - 17h00</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Mardi</td>
                        <td>
                          <tr>9h00 - 12h00</tr> 
                          <tr>13h30 - 17h00</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Mercredi</td>
                        <td>
                          <tr>9h00 - 12h00</tr> 
                          <tr>13h30 - 17h00</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Jeudi</td>
                        <td>
                          <tr>9h00 - 12h00</tr> 
                          <tr>13h30 - 17h00</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Vendredi</td>
                        <td>
                          <tr>09h00 - 12h00</tr> 
                          <tr>13h30 - 17h00</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Samedi</td>
                        <td>Fermé</td>
                      </tr>
                      <tr>
                        <td>Dimanche</td>
                        <td>Fermé</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Row>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Aidotec;