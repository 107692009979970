import React, {useState, useRef} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom"; 

import authService from "../../services/auth.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Ce champ est obligatoire
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Email invalide
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 30) {
    return (
      <div className="alert alert-danger" role="alert">
        Le mot de passe doit faire entre 6 et 30 caractères.
      </div>
    );
  }
};

const Register = (props) => {

  const form = useRef();
  const checkBtn = useRef();

  let navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [roles, setRoles] = useState([]);
  const [email, setEmail] = useState('');
  const [adress, setAdress] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState(0);
  const [plainPassword, setPlainPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePlainPassword = (e) => {
    const plainPassword = e.target.value;
    setPlainPassword(plainPassword);
  };

  const onChangeRepeatPassword = (e) => {
    const repeatPassword = e.target.value;
    setRepeatPassword(repeatPassword);
  };

  const onChangeFirstName = (e) => {
    const firstName = e.target.value;
    setFirstName(firstName);
  };

  const onChangeLastName = (e) => {
    const lastName = e.target.value;
    setLastName(lastName);
  };

  const onChangeAdress = (e) => {
    const adress = e.target.value;
    setAdress(adress);
  };

  const onChangeZipCode = (e) => {
    const zipCode = e.target.value;
    setZipCode(zipCode);
  };

  const onChangeCity = (e) => {
    const city = e.target.value;
    setCity(city);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      if(repeatPassword === plainPassword) {
        authService.register(email, plainPassword, firstName, lastName, adress, zipCode, city)
          .then((response) => {
            setMessage(response.data.message);
            setSuccessful(true);
            authService.login(email, plainPassword)
            .then(
              () => {
                navigate('/account/')
                window.location.reload();
              },
              (error) => {
                const resMessage =
                  (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  error.message ||
                  error.toString();
    
                setMessage(resMessage);
              }
            )
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            setMessage(resMessage);
            setSuccessful(false);
          }
        );
      } else {
        const resMessage = 'Les mots de passe ne sont pas les mêmes ! ';
        setMessage(resMessage);
        setSuccessful(false);
      }
    }
  }

  return (
    <main>
      <div className="main-content mt-1">  
        <div>
          <section className="section-pagetop bg">
            <Container>
              <div className="card mx-auto" style={{ maxWidth: '520px'}}>
                <article className="card-body">
                  <header className="mb-4">
                    <h4 className="card-title">Inscription</h4>
                  </header>
                  <Form onSubmit={handleRegister} ref={form}>
                    {!successful && (
                      <>
                      <div className="form-row">
                        <div className="col form-group">
                          <label>Prénom</label>
                            <Input type="text" className="form-control" value={firstName} placeholder="" onChange={onChangeFirstName}  validations={[required]} />
                        </div>
                        <div className="col form-group">
                          <label>Nom</label>
                            <Input type="text" className="form-control" value={lastName} placeholder="" onChange={onChangeLastName}  validations={[required]} />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Email</label>
                        <Input type="email" className="form-control" value={email} placeholder="" onChange={onChangeEmail} validations={[required, validEmail]} />
                      </div>
                      <div className="form-group">
                        <label>Adresse</label>
                        <Input type="text" className="form-control" value={adress} placeholder="" onChange={onChangeAdress} validations={[required]} />
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>Ville</label>
                          <Input type="text" className="form-control" value={city} onChange={onChangeCity} validations={[required]}  />
                        </div>
                        <div className="form-group col-md-6">
                          <label>Code postal</label>
                          <Input type="text" className="form-control" value={zipCode} onChange={onChangeZipCode} validations={[required]} />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>Mot de passe</label>
                            <Input className="form-control" type="password" autoComplete="new-password" value={plainPassword} onChange={onChangePlainPassword} validations={[required, vpassword]}  />
                        </div> 
                        <div className="form-group col-md-6">
                          <label>Répèter le mot de passe</label>
                            <Input className="form-control" type="password" autoComplete="new-password" value={repeatPassword} onChange={onChangeRepeatPassword} validations={[required, vpassword]} />
                        </div>  
                      </div>
                      <div className="form-group mt-2">
                        <button type="submit" className="btn btn-primary btn-block"> Inscription  </button>
                      </div>    
                      <div className="form-group"> 
                        <label className="custom-control custom-checkbox"> 
                          <Input type="checkbox" className="custom-control-input" checked="agree" /> 
                          <span className="custom-control-label"> J'accepte les <Link to="/disclaimer">termes et conditions</Link>  
                          </span> 
                        </label>
                      </div>  
                      </>
                    )}  
                    {message && (
                      <div className="form-group">
                        <div
                          className={ successful ? "alert alert-success" : "alert alert-danger" }
                          role="alert"
                        >
                          {message}
                        </div>
                      </div>
                    )}
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />       
                  </Form>
                </article>
              </div>
              <p className="text-center mt-4">Vous avez déjà un compte ? <Link to="/login">Connectez-vous !</Link></p>
            </Container>
          </section>
        </div>
      </div>
    </main>
  );
};

export default Register;