import { Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom"; 

const SectionHeader = (props) => {
  return (
    <div>
      <section className="section-pagetop">
        <Container>
          <h2 className="title-page">{props.title}</h2>
        </Container>
      </section>
    </div>
  );
};

export default SectionHeader;
