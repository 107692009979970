import { Container, Nav, Row, Card, Table, Image } from "react-bootstrap";
import { Link } from "react-router-dom"; 
import bannerRel from "../../assets/img/banner-rel.png";
import aero from "../../assets/img/aerogommage.png";
import aerog from "../../assets/img/aero.png";
import relooking from "../../assets/img/relooking.jpg";
import "./About.css";

const Other = (props) => {
  return (
    <main className="main-content mt-2">
        <div className="banner">
        <Image src={bannerRel} className="w-100"/> 
      </div>
      <section className="section-pagetop bg frel">
        <Container>
          <h2 className="title-page">Autre service : le relooking</h2>
          <Row className="col-8">
            <h3>Qu'est ce que le Relooking ?</h3>
            <p>A partir de matériaux récupérés en déchèterie, nous relookons les meubles qui seront vendus au magasin Vue d'en Face. L'occasion pour nos agents ressourciers d'apprendre le travail du bois, le ponçage et la peinture.</p> 
            <p> Le relooking, c'est aussi l'art de détourner des meubles de leur fonction première, en transformant par exemple une servante de couture en coiffeuse. </p>
          </Row>
          <Row className="my-2">
            <Card className="col-lg-6 m-2" id="enc">
              <Card.Body>
                <div>
                  <h4>Vente de mobilier upcyclé</h4>
                  <p>D'une remise au brut, ou avec une mise en peinture, nous nous occupons de donner une seconde jeunesse aux meubles en bois. </p>
                  <Image src={relooking} alt="table relookée" className="img-fluid rounded" />  
                </div>
              </Card.Body>
            </Card>
            <Card className="col-lg-5 m-2" id="enc">
              <Card.Body>
                <div>
                  <h4>Vente de meuble aérogommés</h4>
                  <p>L'aérogommage est une méthode de décapage pratiquée à l'aide de sable ! Rapide et Ecologique elle redonne vie aux meubles en bois et métal. </p>
                  <Image src={aero} alt="meubles aerogommés" className="img-fluid rounded" />
                  <Image src={aerog} alt="aerogommage d'une armoire" className="img-fluid mt-3 rounded" />
                </div>
              </Card.Body>
            </Card>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Other;