import { useStripe, useElements, CardElement, PaymentElement } from '@stripe/react-stripe-js';
import { Alert, Container } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import Icofont from 'react-icofont';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import checkoutService from "../../services/checkout.service";
import authService from '../../services/auth.service';
import { CartState } from '../../context/Context';
import productService from '../../services/product.service';

export const CheckoutForm = ({ total }) => {

  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState('');
  const [lastOrder, setLastOrder] = useState(0);
  const [products, setProducts] = useState([]);

  let navigate = useNavigate();
  
  const {
    state : { cart },
    dispatch,
  } = CartState();

  const getLastOrder = useEffect(() => {
    let orderNumber = checkoutService.getLastOrderId(setLastOrder);
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();

    // console.log('lastorder Id = '+ lastOrder)

    if (!stripe || !elements) {
      return;
    }

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    }) 
    if(!error){
      if(lastOrder !== undefined) {
        // console.log(paymentMethod);
        checkoutService.payment(paymentMethod.id, total*100);
        setLastOrder(lastOrder + Number(1));

        const orderData = {
          status: "payée",
          number: 2022+'/'+ lastOrder,
          user: '/api/users/' + authService.getCurrentUser(),
          products: productService.getProductsInCart(cart)
        }
        console.log(orderData);

        const invoiceData = {
          number: 2022+'/'+lastOrder,
          shipmentFees: 0,
          amountExclTax: total,
          total: total, 
          linkedOrder: '/api/orders/' + lastOrder,
        }

        checkoutService.createOrder(orderData);        
        productService.removeProductPostorder(cart, dispatch);
        checkoutService.createBill(invoiceData);
        setMessage("Paiement réussi");
        // navigate("/order/success");
      }

    }
    else{
      setMessage("Le paiement a échoué, merci de réessayer.");
      navigate('/cart');  
    }
  }
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h3>Carte bancaire</h3>
        <CardElement
          options={{
            hidePostalCode: true
          }}
        />
        {/* <PaymentElement /> */}
        <Container className="mt-3 text-center">
          <button class="white btn btn-primary btn-block mt-2">Payer</button>   
        </Container>        
        {message && message === 'Paiement réussi' ?
          <Alert className="m-3" variant="success"> 
            <Icofont icon="check-circled" /> {message}
          </Alert> : message && message === "Le paiement a échoué, merci de réessayer." ?
          <Alert className="m-3" variant="danger">
            <Icofont icon="close-circled" /> {message}
          </Alert> : ''
        }     
        {/* <PaymentElement /> */}
        {/* <button disabled={!stripe}>Payer</button> */}
      </form>
    </div>
  )
}

