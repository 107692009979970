import React, {useState} from "react";
import { Container, Row, Image, Card, CardGroup, Button, CardImg } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom"; 

import { CartState } from "../../context/Context";
import Icofont from "react-icofont";

import logo from "../../assets/img/logofv.png";

// assets
import "./About.css"
import logoMateriautheque from "../../assets/img/logo-mat.png";
import logoAidotec from "../../assets/img/logo-aidotec.svg";
import logoRessourcerie from "../../assets/img/logo-ressourcerie.jpg";

import mat from "../../assets/img/mat1.jpg";
import mat2 from "../../assets/img/mat2.jpg";

import aid from "../../assets/img/aid.jpg";
import aid1 from "../../assets/img/aid1.png";
import aid3 from "../../assets/img/aid3.png";
import aid4 from "../../assets/img/aid4.jpg";

import res3 from "../../assets/img/res3.png";
import res4 from "../../assets/img/res4.jpg";
import res5 from "../../assets/img/res5.jpg";
import res8 from "../../assets/img/res8.jpg";

import bandeauplie from "../../assets/img/bandeau-plie.png";

// Components
import Hero from "./Hero";
// import Newhero from "./Newhero";

const About = () => {

  const {
    state: { cart },
  } = CartState();

  let location = useLocation();

  return (
    <main>
      <div className="about bg-sand pb-5">  
        <Hero />         
        {/* <Row className="justify-content-around" style={{padding: 60}}>
          <Card className="header-card dga">
            <div className="cat-cont">
              <h4 className="cat-title">Inclusion</h4>
            </div>
          </Card>
          <Card className="header-card dge">
            <div className="cat-cont">
              <h4 className="cat-title">Environnement</h4>
            </div>
          </Card>
          <Card className="header-card dgi">
            <div className="cat-cont">
              <h4 className="cat-title">Economie Sociale et Solidaire</h4>  
            </div>
          </Card>
        </Row> */}

        <Container className="mt-3">
          <div className="section-title">
            <h1 className="text-center"> <span className="bg-wh">&nbsp; Notre mission : l'<span>Insertion</span> &nbsp;</span></h1>
          </div>
          <Row className="activities justify-content-around bg-res my-3">
            <Container className="justify-content-center">
              <Row>
                <Card className="col-lg-12 card-res">  
                  <div className="logo-container">
                    <Card.Img src={logo} variant="top" className="logo m-2"/>
                  </div>        
                  <Card.Body>
                    <div>
                      <p>
                        FACE VALO est une association qui a deux fonctions principales :
                      </p>
                      <p>
                      Recycler et revaloriser les déchets, et <span> réinsérer des personnes dans la vie professionnelle</span>.

                      C'est par la collecte, la revalorisation et la revente des objets que les salariés sont réorientés vers l'emploi.
                      Structure d'insertion par l'activité économique, la Ressourcerie du Calaisis est une enseigne qui crée de
                      l'emploi sur le territoire du Calaisis pour les personnes les plus éloignées du monde du travail.
                      </p>

                      <h2 class="act-title text-align-center"> Dispositifs  <span class="span-res">partenaires &nbsp;</span></h2>

                      <Card className="col-lg-12 card-res m-1">  
                        <h2 className="m-2">L'opération "A compétences valorisables"</h2>   
                        <Card.Body>
                          <div>
                            <p>Le projet vise l'insertion professionnelle. Pour atteindre cet objectif, la Ressourcerie FACE VALO, association loi 1901, agréée Atelier Chantier Insertion (ACI) propose une étape de parcours d'insertion sous un statut de salarié en Contrat à Durée Déterminée d'Insertion (CDDI) pour 7 postes PLIE. Les contrats d'une durée de 4 à 18 mois, pour une durée hebdomadaire de 26 heures, permettent sur la durée du contrat un encadrement technique des personnes et un accompagnement socio-professionnel sur le temps de travail.</p>

                            <p>L'encadrement technique, assuré par deux salariés permanents pour 7 postes en insertion a pour objectif de développer les compétences professionnelles des personnes accompagnées et de redonner les comportements attendus par les entreprises (ponctualité, respect des consignes de sécurité, le langage approprié, la confiance en soi, etc.)</p>

                            <p>L'accompagnement socio-professionnel, réalisé par notre référente socio-professionnelle a pour objectif de <span>lever les freins d'accès à l'emploi (garde d'enfants, aisance relationnelle, logement, budget...) </span> ; de travailler sur le projet professionnel de chaque personne accompagnée et de proposer des offres d'emploi et de formation. Cela pour permettre, à tout moment propice, une sortie du projet en emploi ou par l'intégration d'une formation qualifiante, professionnalisante dans le cadre du projet professionnel travaillé.

                            L'action proposée s'appuie sur 2 de nos activités : l'atelier relooking de meubles et l'atelier d'aides d'occasion techniques.
                            </p>   
                          </div>              
                        </Card.Body>
                        <CardImg src={bandeauplie} className="w-100 my-1 bord-circled"></CardImg>
                      </Card>
                      {/* <Card className="col-lg-6 card-res m-1"> 

                      </Card> */}
                    </div>              
                  </Card.Body>
                </Card>                      
              </Row>
            </Container>
          </Row>
        </Container>

        <Container className="mt-3">
          <div className="section-title">
            <h1 className="text-center"> <span className="bg-wh">&nbsp; Nos activités <span>en bref</span> &nbsp;</span></h1>
          </div>
          <Row className="activities justify-content-around bg-res my-3">
            <h2 className="act-title text-align-center"> La Ressourcerie <span className="span-res">du Calaisis &nbsp;</span></h2>
            <Container className="justify-content-center">
              <Row>
                <Card className="col-lg-5 card-res">  
                  <div className="logo-container">
                    <Card.Img src={logoRessourcerie} variant="top" className="logo mt-2"/>
                  </div>        
                  <Card.Body>
                    <div>
                      <p>
                        La Ressourcerie est membre du <span>réseau National des Ressourceries</span>. 
                        Depuis 2011, c’est un lieu d’échange qui permet de recycler et de revaloriser les déchets et de réinserrer des personnes dans la vie professionnelle.
                      </p>

                      <span><p>Les principales activités de la Ressourcerie sont : </p></span>
                      <ul>
                        <li>Tri sélectif et revalorisation des matières premières</li>
                        <li>Tri sélectif et revalorisation des matières premières</li>
                        <li>Réparation et revente de produits d’équipements électroménagers</li>
                        <li>Relooking de meubles</li>
                        <li>Vente aux particuliers d’articles d’occasion de meubles, textiles, produits ludiques</li>
                      </ul>              
                    </div>              
                  </Card.Body>
                </Card>

                <div className="col-lg-7">
                  <Row>
                    <div className="col-lg-5">
                      <figure>
                        <Image src={res3} className="w-100 my-1 bord-circled" />
                        <figcaption><h4 className="text-center">Collecte en déchèterie</h4></figcaption>
                      </figure>
                      <figure>
                        <Image src={res4} className="w-100 my-1 bord-circled" />
                        <figcaption><h4 className="text-center">Collecte à domicile</h4></figcaption> 
                      </figure>
                    </div>
                    <div className="col-lg-6">
                      <figure>
                        <Image src={res8} className="w-100 my-1 bord-circled" />
                        <figcaption><h4 className="text-center">Vente à la Ressourcerie</h4></figcaption>
                        <Image src={res5} className="w-100 my-1 bord-circled" />
                      </figure>
                    </div>
                  </Row>
                </div>  
              </Row>
            </Container>
          </Row>
        </Container>

        <div className="cta m-2 bg-white">
          <p className="text-center m-2">Pour en savoir plus, c'est <Link to="/ressourcerie" className="hover-underline">par ici !</Link></p>
        </div>

        <Container>
          <Row className="my-2 activities justify-content-around mat">
            <h2 className="act-title text-align-center"> La Matériauthèque <span className="span-mat">du Calaisis &nbsp;</span></h2>

            <Container className="justify-content-center">
              <Row>
                <div className="col-lg-4">
                  <figure>
                    <Image src={mat} className="w-100 my-1 bord-circled" />
                  </figure>
                  <figure>
                    <Image src={mat2} className="w-100 my-1 bord-circled" />
                    <figcaption className="fig-mat">
                      <h4 className="text-center">Vente de matériaux à l'unité</h4>
                    </figcaption>
                  </figure>
                </div>
              
                <Card className="col-lg-6 fmat card-mat">
                  <div className="logo-container">
                    <Card.Img src={logoMateriautheque} variant="top" className="logo mt-2"/>
                  </div>
                  <Card.Body>
                    <p>
                      La Matériauthèque est un Atelier Chantier d'Insertion dont l'objet est de permettre à des personnes en difficultés d'insertion de d'occuper un emploi, de développer des compétences, de retrouver des habitudes professionnelles et de se réinsérer professionnellement durablement.
                      Depuis le 1er décembre 2021, la Matériauthèque propose aux particuliers de s'équiper en matériaux de seconde vie à moindre coût.  
                    </p>

                    <span><p>Les principales activités de la Matériauthèque sont : </p></span>
                    <ul>
                      <li>Tri sélectif et revalorisation des matières premières</li>
                      <li>Récupération des fin de chantier</li>
                      <li>Collecte en entreprise et chez les particuliers</li>
                      <li>Revente à moindre coût</li>
                    </ul>
                  </Card.Body>
                </Card>              
              </Row>
            </Container>
          </Row>
        </Container>

        <div className="cta bg-white m-2">
          <p className="text-center m-2">Pour en savoir plus, c'est <Link to="/materiautheque" className="hover-underline">par ici !</Link></p>
        </div>

        <Container>
          <Row className="my-2 activities justify-content-around aid">
          <h2 className="act-title text-align-center"> AIDOTEC <span> &nbsp;</span></h2>
            <Card className="col-lg-6 faid card-aid"> 
              <Card.Body>
                <div className="logo-container">
                  <Card.Img src={logoAidotec} variant="top" className="logo mt-2"/>
                </div>
                  <p>
                    AIDOTEC est un dispositif créé en partenariat avec le département du Pas-de-Calais. Nous récupérons les aides techniques médicales inutilisées (fauteuil releveur, élévateur de bain, fauteuil roulant...) pour les redistribuer aux bénéficiaires de l'APA (Allocation Personnalisée d'Autonomie) ou de la PCH (Prestation de Compensation du Handicap) à moindre coût.
                    Nous nous déplaçons dans tout le département, pour plus d'information, rendez vous sur <Link to="https://aidotec.fr">Aidotec.fr</Link>.
                  </p>

                  <span><p>Les principales activités d'AIDOTEC sont : </p></span>
                  <ul>
                    <li>La collecte de matériel médical inutilisé</li>
                    <li>La désinfection et la réparation d'aides techniques</li>
                    <li>La livraison chez les bénéficiaires de nos aides techniques</li>
                  </ul>
              </Card.Body>
            </Card>
            <div className="col-lg-6">
              {/* <Image src={aid} className="w-100 m-1" /> */}
              <Row>
                <div className="col-lg-5">                 
                  <figure>
                    <Image src={aid1} className="w-100" />
                    <figcaption><h4 className="text-center">Collecte à domicile</h4></figcaption>
                  </figure>

                  <figure>
                    <Image src={aid4} className="w-100 my-1" />
                    <figcaption><h4 className="text-center">Réparation</h4></figcaption> 
                  </figure>
                </div>
                <div className="col-lg-7">
                  <figure>
                    <Image src={aid} className="w-100 my-1" />
                    <figcaption><h4 className="text-center">Redistribution</h4></figcaption>
                  </figure>
                </div>
              </Row> 
            </div>
          </Row>
        </Container>
      </div>
    </main>
  );
};

export default About; 