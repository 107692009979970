import React, {useState, useEffect } from "react";
import axios from "axios";
import { Container, Alert } from "react-bootstrap";
import { useLocation } from "react-router-dom"; 
import ReactPaginate from 'react-paginate';
import Icofont from "react-icofont";

// component
import SectionHeader from "../sectionheader/SectionHeader";
import Filter from "../filter/Filter";
import Product from "../product/Product";

import { CartState } from "../../context/Context";

const Products = () => {

  const {
    state: {products, cart},
  } = CartState();

  let location = useLocation();

  function GetProducts({currentItems}) {
    return(
      <>
        <div className='row'>
          {currentItems ?
            currentItems.map((prod) => {
              return <Product prod={prod} key={prod.id} />
            }) : <p>Aucun produit disponible</p>
          }
        </div>
      </>
    );
  }

  function PaginatedProduct({ itemsPerPage }) {
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(products.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(products.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % products.length;
      // console.log(
      //   `User requested page number ${event.selected}, which is offset ${newOffset}`
      // );
      setItemOffset(newOffset);
    };

    return(
      <>
        <GetProducts currentItems={currentItems} />
        <ReactPaginate
          breakLabel="..."
          nextLabel="Suivant >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< Précédent"
          renderOnZeroPageCount={null}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
        />
      </>
    )
  }

  return (
    <main>
      <div className="section-pagetop bg">
        <SectionHeader title='Nos produits'/>
        <Alert variant='warning' className="col-lg-10 mx-auto">
          <p className="text-center"><Icofont icon="info-circle"/> Les produits commandés sont à venir retirer au 147 Boulevard Victor Hugo 62100 Calais, du Lundi au Vendredi entre 9h30 et 16h30. </p>
        </Alert>
        <Container className="main-content mt-1 product">  
          
          <div className="row mb-2">
            <Filter />  
            <section className="col-md-9">
              <PaginatedProduct itemsPerPage={15} />
            </section>
          </div>
        </Container>
      </div>

    </main>
  );
};

export default Products; 