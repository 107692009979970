import { Button, Card, Image } from "react-bootstrap";
import Icofont from 'react-icofont';

import { CartState } from "../../context/Context";

// assets
import defaultImg from "../../assets/img/default.png";
import { Link } from "react-router-dom";

const Product = ({ prod }) => {

  const {
    state: { cart },
    dispatch,
  } = CartState();

  return (
    <Card className="col-lg-3 m-2">
      {prod.media != null ?
        <Card.Img variant="top" src={prod.media[0].filePath} alt={prod.name} className="img-sm"/>
        : 
        <Card.Img variant="top" src={defaultImg} alt={prod.name} className="img-sm"/>
      }
      <Card.Body>
        <Card.Title>{prod.name}</Card.Title>
        <Card.Subtitle style={{ paddingBottom: 10 }}>
          <span>{prod.price} €</span>
        </Card.Subtitle>
          {cart.some((p) => p.id === prod.id) ? (
            <Button
              variant="outline-danger btn-block btn-sm"
              style={{ color: '#dc3545 !important' }}
              onClick={() =>
                dispatch({
                  type: 'REMOVE_FROM_CART',
                  payload: prod,
                })
              }
            >
              X
            </Button> 
            ) : (
              <>
              <Button 
                onClick={() => 
                  dispatch({
                    type: 'ADD_TO_CART',
                    payload: prod
                  })
                }
                variant="outline-primary btn-block btn-sm" disabled={!prod.isAvailable || prod.quantity <= 0} style={{ display: 'block' }}>
                {!prod.isAvailable || prod.quantity <= 0 ? "Non disponible" : <> <Icofont icon="cart" /> Ajouter au Panier </> }
              </Button>
              <Link className="btn btn-primary btn-block btn-sm mt-1 " to={{ pathname:'/product/' + prod.id, state:prod}} style={{ display: 'block' }}>
                <Icofont icon="eye"/>&nbsp;
                Voir
              </Link>
              </>
            )
          }
      </Card.Body>
    </Card>
  );
}

export default Product;