import React, {useState, useEffect} from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom"; 

// component
import SectionHeader from "../components/sectionheader/SectionHeader";
import { CartState } from "../context/Context";
import About from "../components/about/About";

const Home = () => {

  const {
    state: { cart },
  } = CartState();

  let location = useLocation();

  return (
    <>
      {/* <Sectionheader title='Accueil'/> */}
      <div className="main-content mt-1">  
        {location.pathname === '/' ?
          <About /> : ''
        }
      </div>
    </>
  );
};

export default Home; 