import { Container, Nav, Row, Card, Table, Image, Alert } from "react-bootstrap";
import { Link } from "react-router-dom"; 
import Icofont from "react-icofont";
import bannerPro from "../../assets/img/MB-cover.png";

const Professionnel = () => {
  return (
    <main className="main-content mt-2">
      <div className="banner">
        <Image src={bannerPro} className="w-100"/> 
      </div>
      <section className="section-pagetop bg fpro">
        <Container>
          <h2 className="title-page">Le mobilier Professionnel</h2>
          <Row className="col-8">
            <h3>Qu'est ce que le Mobilier Professionnel ?</h3>
            <p>Notre pôle <span>Mobilier Professionnel</span> rassemble tout le mobilier : Bureaux, armoires métalliques, caisson de bureaux, armoires basses ou chaises; dont un professionnel pourrait avoir besoin ! </p>
            <p>Les meubles sont révisés, réparés, nettoyés, vendus au détail ou par lot. Ils sont visibles dans les locaux de FACE VALO, <em>147 Boulevard Victor Hugo 62100 Calais </em>. </p>
          </Row>
          <Row className="my-2"> 
            <Row className="justify-content-around col-lg-8">
              <h3>Nos services</h3>
              <Card className="col-lg-5 m-1">
                <Card.Body>
                  <div>
                    <h4>Collecte de mobilier professionnel en entreprises et chez des particuliers </h4>
                    <p>Nos agents ressourciers se déplacent dans l'agglomération Grand Calais Terres & Mers pour venir collecter des dons.</p>  
                    <p>Pour prendre rendez-vous, contactez nous au 03.59.44.37.56.</p>
                  </div>
                </Card.Body>
              </Card>
              <Card className="col-lg-5 m-1">
                <Card.Body>
                  <div>
                    <h4>Débarras de mobilier professionnel</h4>
                    <p>Si votre matériel n'est plus en état d'être réemployé, et que vous souhaitez débarasser vos locaux, nous proposons des prestations de débarras avec si besoin la pose d'une benne devant vos locaux. </p>
                    <p>Cette prestation se fait sur devis.</p>  
                  </div>
                </Card.Body>
              </Card>
            </Row>
            <Row className="col-lg-4 align-items-flex-start">
            <h3>Horaires & infos</h3>
              <Alert variant="secondary">   
                <Icofont icon="phone-circle" /> &nbsp;
                Contactez nous au 07.88.11.27.72
              </Alert>
              <Card>
                <Card.Body>
                  <Table hover>
                    <thead>  
                      <h4 style={{ fontSize: "1.3rem"}}>Horaires FACE Valo</h4>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Lundi</td>
                        <td>
                          <tr>10h00 - 12h00</tr> 
                          <tr>13h30 - 17h00</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Mardi</td>
                        <td>
                          <tr>10h00 - 12h00</tr> 
                          <tr>13h30 - 17h00</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Mercredi</td>
                        <td>
                          <tr>10h00 - 12h00</tr> 
                          <tr>13h30 - 17h00</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Jeudi</td>
                        <td>
                          <tr>10h00 - 12h00</tr> 
                          <tr>13h30 - 17h00</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Vendredi</td>
                        <td>
                          <tr>10h00 - 12h00</tr> 
                          <tr>13h30 - 17h00</tr>
                        </td>
                      </tr>
                      <tr>
                        <td>Samedi</td>
                        <td>Fermé</td>
                      </tr>
                      <tr>
                        <td>Dimanche</td>
                        <td>Fermé</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Row>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Professionnel;