import { useState, useEffect } from "react";
import { Button, Container, Image, Alert } from "react-bootstrap";
import { Link, useNavigate, useLocation, Route } from "react-router-dom";

import Icofont from "react-icofont";

// components
import TermsOfSales from "../disclaimer/TermsOfSales";
import SectionHeader from "../sectionheader/SectionHeader";
import CardRow from "./CardRow";
import { CartState } from "../../context/Context";
import axios from "axios";

import { CheckoutForm } from "../checkout/Checkout";

// services
import authService from "../../services/auth.service";
import checkoutService from "../../services/checkout.service";

const Cart = () => {
  const [status, setStatus] = useState('En attente de paiement');
  const [user, setUser] = useState();
  const location = useLocation();

  let navigate = useNavigate();

  const HandleCheckout = (e) => {
    e.preventDefault();

    navigate('/checkout');
  }

  const {
    state : { cart, products },
    dispatch,
  } = CartState();

  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotal(
      cart.reduce((acc, curr) => acc + Number(curr.price) * curr.qty, 0)
    );
  }, [cart]);

  useEffect(() => {
    setUser(authService.getCurrentUser())
  }, []);

  return (
    <main className="main-content section-pagetop bg">
      <SectionHeader title='Panier' />
      <Container>
        <Alert variant='warning' className="col-lg-11 mx-auto">
          <p className="text-center"><Icofont icon="info-circle"/> Les produits commandés sont à venir retirer au 147 Boulevard Victor Hugo 62100 Calais, du Lundi au Vendredi entre 9h30 et 16h30. </p>
        </Alert>
        <div className="container cart">
          <div className="row">
            <main className="col-md-9">
              <div className="card">
                <table className="table table-borderless table-shopping-cart">
                  <thead className="text-muted">
                    <tr className="small text-uppercase">
                      <th scope="col">Produit</th>
                      <th scope="col"></th>
                      <th scope="col" width="120">Quantité</th>
                      <th scope="col" width="120">Prix</th>
                      <th scope="col" className="text-right" width="200">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart !== undefined ? 
                      (
                        <>
                          {
                            cart.map((prod) => (
                              <CardRow prod={prod} key={prod.id} />
                            ))
                          }
                        </>
                      ) : (
                        <tr style={{textAlign:'center'}}>Votre panier est vide !</tr>
                      )
                    }
                  </tbody>
                </table>

                <div className="card-body border-top">
                  <Link to="/products" className="btn btn-outline-primary">  
                    <Icofont icon="curved-left" /> Continuer mes achats 
                  </Link>
                </div>	
              </div>
            </main>

            <aside className="col-md-3">
              <div className="card">
                <div className="card-body">
                  <dl className="dlist-align">
                    <dt>Prix total:</dt>
                    <dd className="text-right">{total} €</dd>
                  </dl>
                  <dl className="dlist-align">
                    <dt>Remise</dt>
                    <dd className="text-right"> 0 </dd>
                  </dl>
                  <dl className="dlist-align">
                    <dt>Total:</dt>
                    <dd className="text-right  h5"><strong> {total} €</strong></dd>
                  </dl>
                  <hr />
                  {cart !== undefined && cart.length > 0 ? 
                    <>
                      <Link className="btn btn-primary" to="" onClick={HandleCheckout}>Paiement
                        <Icofont icon="curved-right" /> 
                      </Link>
                    </> : ''
                  }
                </div>
              </div>
            </aside>
          </div>
        <TermsOfSales />
        </div> 
      </Container>
    </main>
  );
};


export default Cart;