import { Container, Nav, Row, Card, Table, Image, Alert } from "react-bootstrap";
import Icofont from "react-icofont";
import { Link } from "react-router-dom"; 
import bandeaurmm from "../../assets/img/Rmm-cover.png";
import imgrmm from "../../assets/img/rmm.jpg";
import "./About.css";

const RessMatMed = () => {
  return (
    <main className="main-content mt-2">
      <div className="banner">
        <Image src={bandeaurmm} className="w-100" /> 
      </div>
      <section className="section-pagetop bg fvdf">
        <Container>
          <h2 className="title-page">La Ressourcerie du Matériel Médical</h2>
          <Row className="col-8">
            <h3>Qu'est ce que La Ressourcerie du Matériel Médical ?</h3>
            <p>La Ressourcerie du Matériel Médical est une section dédiée aux <em>aides techniques</em> (fauteuils roulants, rollator et déambulateurs) implanté au sein du magasin de la ressoucerie. Ce projet à deux objectifs :</p>
            <ul>
              <li>Former les personnes en CDDI à la vente d'articles spécifiques.</li>
              <li>Exposer et vendre les <em>aides techniques</em> vérifiées et controlées à l'atelier.</li>
            </ul>
          </Row>

          <Row className="my-2">
            <Row className="justify-content-around col-lg-8">
              <h3>Nos services</h3>
              <Row>
                <Alert variant="warning" className="m-1 d-flex justify-content-center align-items-center">   
                  <h4> Votre matériel n'est pas présent en magasin ? Demandez le nous à la caisse !  </h4>
                </Alert>
              </Row>
              <Row className="col-lg-7">
                <Card className="m-1" id="enc">
                  <Card.Body>
                    <div>
                      <h4>Vente d'aide techniques de seconde main</h4>
                      <p>Contrôles et désinfection réalisés par l'équipe <em>d'AIDOTEC</em> </p>  
                      <Image src={imgrmm} className="img-fluid"></Image>
                    </div>
                  </Card.Body>
                </Card>
              </Row>
              <Row className="col-lg-5">
                <Card className="m-1" id="enc">
                  <Card.Body>
                    <div>
                      <h4>Commande et conseil</h4>
                      <p> Besoin d'un renseignement ? Une taille différente de celle exposée en magasin ? Faites en la demande à notre équipe, votre matériel sera disponible sous 1 semaine.</p>
                    </div>
                  </Card.Body>
                </Card>
              </Row>
            </Row>
            <Row className="col-lg-4 align-items-flex-start">
              <h3>Horaires & infos</h3>
              <Card>
                <Card.Body>
                    <Table hover>
                      <thead>  
                        <h4 style={{ fontSize: "1.4rem" }}>Horaires Magasin</h4>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Lundi</td>
                          <td>Fermé</td>
                        </tr>
                        <tr>
                          <td>Mardi</td>
                          <td>
                            <tr>09h30 - 12h30</tr> 
                            <tr>14h00 - 17h30</tr>
                          </td>
                        </tr>
                        <tr>
                          <td>Mercredi</td>
                          <td>
                            <tr>09h30 - 12h30</tr> 
                            <tr>14h00 - 17h30</tr>
                          </td>
                        </tr>
                        <tr>
                          <td>Jeudi</td>
                          <td>Fermé</td>
                        </tr>
                        <tr>
                          <td>Vendredi</td>
                          <td>
                            <tr>09h30 - 12h30</tr> 
                            <tr>14h00 - 17h30</tr>
                          </td>
                        </tr>
                        <tr>
                          <td>Samedi</td>
                          <td>
                            <tr>09h30 - 12h30</tr> 
                            <tr>14h00 - 17h30</tr>
                          </td>
                        </tr>
                        <tr>
                          <td>Dimanche</td>
                          <td>Fermé</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
              </Card>
            </Row>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default RessMatMed;