import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import Icofont from "react-icofont";

import partners from "../../assets/img/partners.png" ;

import './Footer.css';
  
const Footer = () => {
  return (
    <footer className="section-footer border-top bg-white">
      <Container>
        <section className="footer-top padding-y">
          <div className="row mt-3">
            <aside className="col-md col-6">
              <h6 className="title">Mon Compte</h6>
              <ul className="list-unstyled">
                <li><Link to="/login"> Connexion</Link></li>
                <li><Link to="/register"> Inscription </Link></li>
                {/* <li><Link to=""> Paramètres </Link></li> */}
                {/* <li><Link to="/account"> Mes commandes </Link></li> */}
              </ul>
            </aside>
            <aside className="col-md col-6">
              <h6 className="title">Mentions légales</h6>
              <ul className="list-unstyled">
                <li><Link to="/disclaimer"> Mentions légales</Link></li>
                {/* <li><Link to="/termsofsales"> Conditions générales de vente </Link></li> */}
              </ul>
            </aside>
            <aside className="col-md col-sm-6">
              <h6 className="title">Réseaux</h6>
              <ul className="list-unstyled">
                <li><a href="https://www.facebook.com/laressourcerieducalaisis" target="_blank"><Icofont icon="facebook" /> Facebook</a></li>
              </ul>
            </aside>
          </div>
        </section>

        <section className="footer-bottom row">
        <h6 className="title">Nos partenaires et financeurs</h6> 
          <div className="row">
            <img src={partners}/>
          </div>
          <div className="col-md-4">
            <p className="text-muted"> FACE Valo &copy; 2024 | Tous droits réservés </p>
          </div>
          <div className="col-md-7 text-md-center">
            <span className="px-2">03.21.82.57.29</span>
            <span className="px-2"></span>
            <span className="px-2">1 rue de Judée 62100 Calais</span>
          </div>
          {/* <div className="col-md-1 text-md-right text-muted">
            <Icofont icon="visa" className="m-1"/>
            <Icofont icon="paypal" className="m-1"/>
            <Icofont icon="mastercard" className="m-1"/>
          </div> */}
        </section>
      </Container>
    </footer>
  );
}
export default Footer;