import { Container, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom"; 

import SectionHeader from "../sectionheader/SectionHeader";

const TermsOfSales = (props) => {
  let location = useLocation();

  return (
    <main className="card mt-2">
      <SectionHeader title='Conditions générales de vente'/>
      <Container className="main-content mt-1"> 
        <section className="padding-y mt-2">
          <div className="container">
            <h4>Paiement et conditions générales de vente</h4>
            <h6>Caractéristiques de nos produits</h6>
            <p>L'ensemble de nos produits sont issus du réemploi et par conséquent considérés comme "d'occasion".</p>

            <h6>Prix</h6>
            <p>Les prix de nos produits présentés sur le site facevalo.fr sont affichés en euros toutes taxes comprises hors participation aux frais d'expédition. Le magasin vendeur se réserve le droit de modifier ses prix à tout moment, mais les produits seront facturés sur la base des tarifs en vigueur au moment de l'enregistrement de la commande. </p>

            <h6>Commande</h6>
            <p>Les commandes sont à effectuer sur le site facevalo.fr. FACE VALO se réserve le droit de refuser toute commande d'un client avec lequel il existerait un litige relatif au paiement d'une commande antérieure.</p>

            <h6>Validation</h6>
            <p>Quand le Client clique sur le bouton  » Valider  » après le passage de la commande, il déclare accepter celle-ci ainsi que l’ensemble des présentes Conditions Générales de Vente, pleinement et sans réserve.
            Le Client reconnaît que son acceptation de l’offre est faite en considération de la description du produit objet de la vente et qu’elle vaut engagement ferme et irrévocable de contracter avec le magasin Vendeur aux conditions de l’offre. La Vente est réputée parfaite dès lors que le Client a confirmé son accord inconditionnel à l’offre du magasin Vendeur et que FACE VALO a reçu paiement intégral du prix de la vente.</p>

            <h6>Disponibilité des produits</h6>
            <p>Les offres de produits sont proposées dans la limite des stocks disponibles du magasin Vendeur. Les produits vendus étant des produits d’occasion, souvent uniques, présentés également à la vente dans le magasin La Ressourcerie Vendeur, le Client est informé que ces produits peuvent être vendus dans le magasin Vendeur simultanément ou avant la passation de la commande. En cas d’indisponibilité du produit après passation de la commande, le magasin Vendeur en informera le Client par mail dans les meilleurs délais.</p>

            <h6>Paiement</h6>
            <p>Le règlement des achats en ligne s'effectue par carte bancaire (Visa, Mastercard, autres cartes bleues). </p>

            <h6>Sécurisation</h6>
            <p>Les transactions conclues sur le site facevalo.fr sont sécurisées via le procédé de cryptage SSL. Pour protéger les clients, facevalo.fr ne stocke pas les numéros de carte bancaire sur leurs serveurs informatiques. Les numéros de carte bancaire sont traités par Stripe, qui retourne à facevalo.fr une confirmation de paiment.</p>

            <h6>Droit de rétractation</h6>
            <p>Vous disposez d'un délai de rétractation de 14 jours à compter du lendemain de votre commande. Si ce délai expire un samedi, un dimanche ou un jour férié, il est prolongé jusqu'au premier jour ouvrable suivant. </p>

            <h6>Droit applicable</h6>
            <p>Le présent contrat est soumis à la loi française. Les tribunaux français auront compétence pour se prononcer sur tous les litiges susceptibles de naître entre les parties relatifs à son exécution.</p>

            <h6>Informations nominatives</h6>
            <p>FACE VALO s'engage à ne pas divulguer à des tiers les informations communiquées par le Client. Celles-ci sont confidentielles. Elles ne seront utilisées par les services internes que pour le traitement de la commande.</p>
          </div>
        </section>
      </Container>
    </main>

  );
};

export default TermsOfSales;